import { ChangeDetectionStrategy, Component, Input, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingController } from '@ionic/angular';

import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { BrowserService } from "src/app/services/utils/browser.service";
import { EventsService } from 'src/app/services/core/events.service';
import { NetworkService } from 'src/app/services/core/network.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { ShortcodesService } from 'src/app/services/utils/shortcodes.service';
import { TestingService } from 'src/app/services/core/testing.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'pipeline-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostComponent implements OnInit {
  @Input() post: post;

  posts: post[];

  postsByCategory: post[];

  view: any = {};

  constructor(
    private abo: AbonnementService,
    private browser: BrowserService,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private network: NetworkService,
    private postsService: PostsService,
    private _sanitizer: DomSanitizer,
    private shortcodes: ShortcodesService,
    private testing: TestingService,
    private tools: ToolsService,
    private userService: UserService,
    private zone: NgZone,
  ) {

  }

  async applyABTesting() {
    try {  
      if(this.tools.isWeb()) {
        let testingGroup: testingGroup = await this.testing.getCurrentGroup();
        let postWebTemplate = testingGroup.config.post_web_template;
        let showDownloadCard = testingGroup.config.show_download_card;

        this.zone.run(() => {
          this.view.postWebTemplate = postWebTemplate;
    
          if((postWebTemplate === 'download_wall') && !this.userService.getUid()) {
            this.view.downloadWall = true;
          }
    
          this.view.showDownloadCard = showDownloadCard && !this.userService.getUid();
          this.detectChanges();
        });
      }
    } catch(e) {
      console.warn('testing error', e);
    }
  }

  detectChanges() {
    this.events.publish('post:reader:update', this.post);
  }

  hideVideoLoading() {

    if(this.view.videoLoading) {
      this.view.videoLoading.dismiss();
    }

    let video: any = document.querySelectorAll('.post-page video');

    if(video && video[0]) {
      video[0].play();
    }

    this.detectChanges();
  }

  loaded() {
    this.zone.run(() => {
      this.post.thumbnailBackground = this._sanitizer.bypassSecurityTrustStyle('url(' + this.post.thumbnail + ')');
      this.post.vip = (this.abo.calcVip(this.post) && !this.view.isPreviewMode);
      
      let post = this.shortcodes.prepare(this.post, 'post_content');
      let content = post ? (post.post_content || this.post.post_content) : this.post.post_content;

      // detect if video mode
      this.view.mode = this.tools.isVideoModeContent(content) ? 'video' : this.view.mode;
      
      if(this.view.mode === 'video') {
        this.showVideoLoading();
        this.view.short = false;
      }
      
      this.post.loaded = true;
      this.detectChanges();

      setTimeout(async () => {
        let blConnected: boolean = await this.network.isConnected();
        
        if(blConnected) {
          this.loadRelatedArticles();
        
          if(!this.postsByCategory) {
            this.loadPostsByCategory();
          }
        }

        this.detectChanges();
      });
    });
  }

  loadPostsByCategory() {
    this.postsService.getPostsByCategory(this.post.category, {
      active: true,
    })
    .then((postsByCategory: any) => {
      this.zone.run(() => {

        if(postsByCategory && postsByCategory.length) {
          this.postsByCategory = this.tools.shuffle(
            postsByCategory.filter((_post: post) => {
              return _post.uid !== this.post.uid && _post.active;
            })
          );

          this.postsByCategory.forEach((postByCategory: post) => {
            postByCategory.post_content_formatted = this._sanitizer.bypassSecurityTrustHtml(postByCategory.post_content);
          });
          
        }
        
        setTimeout(() => {
          this.posts = (this.posts || [this.post]).concat(this.postsByCategory || []);

          this.view.canBack = false;
          this.view.canNext = this.posts && (this.posts.length > 1);

          this.detectChanges();
        });

      });
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  loadRelatedArticles() {
    if(this.post.relatedArticles && this.post.relatedArticles.length) {
      this.postsService.getFullRelatedArticles(this.post.relatedArticles)
        .then((relatedArticles: post[]) => {
          this.post.relatedArticles = relatedArticles;
          this.detectChanges();
        })
        .catch((error) => {
          console.warn('related calc error', error);
        });
    }
  }
  
  
  

  ngOnInit() {
    this.applyABTesting();
    this.loaded();
  }

  async showVideoLoading() {

    this.view.videoLoading = this.view.videoLoading || (await this.loadingCtrl.create({
      spinner: 'circular',
    }));

    if(this.view.videoLoading) {
      this.view.videoLoading.present();
    }

    setTimeout(() => {
      this.hideVideoLoading();
    }, 1000);

    this.detectChanges();
  }


}
