import { Injectable } from '@angular/core';

import { Browser } from '@capacitor/browser';
//import { InAppBrowser } from '@capgo/inappbrowser';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
  ) {
  }

  create(
    url: string,
  ) {
    return new Promise(async (resolve, reject) => {
      url = url || '';

      if (!!url) {

        if (url.indexOf('http') !== 0) {
          url = `http://${url}`;
        }

        let browser: any = await Browser.open({
          url: url,
          //toolbarColor: '#6e52fb',
          presentationStyle: 'popover',
        });

        console.log('browser', browser);

        resolve(true);
      }
    });
  }

}
