import { Component, OnInit, ViewChild } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from "src/app/services/core/view.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-ai-store',
  templateUrl: './ai-store.page.html',
  styleUrls: ['./ai-store.page.scss'],
})
export class AiStorePage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  fallbackImg: string = './assets/img/fallback.webp';
  
  search: searchOptions = {
    itemsKey: 'templates',
    keys: ['name', 'post_content', 'host', 'url', 'uid'],
    query: "",
  };

  state: state = {};
  
  view: any = {
    filters: {
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_store_top_card',
      text: 'ai_store_top_card_text',
      title: 'ai_store_top_card_title',
    },
    itemSize: 72,
    multiple: true,
    placeholder: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
    route: 'ai/store',
    segment: 'tasks',
    selectionOptions: [
      {
        icon: 'cloud-download-outline',
        label: 'import',
        uid: 'import',
      }
    ],
    showProjectsSelect: false,
    tasks: [],
    title: 'ai_store',
  };

  constructor(
    private ai: AiToolsService,
    private events: EventsService,
    private modalService: ModalService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.view.promptTypes = this.ai.getPromptTypes();
  }

  buy(task: any) {

  }

  calcItems() {
    switch(this.view.segment) {
      case 'shortcodes':
        this.view.items = (this.view.shortcodes || []);
        break;
      case 'tasks':
        this.view.items = (this.view.tasks || []);
        break;
    }
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canDelete = this.userService.isType(['Admin', 'Moderator']);
    this.view.canPublish = this.userService.isType(['Admin', 'Moderator']);
    this.view.canSubmitForReview = this.userService.isType(['Admin', 'Redakteur', 'Moderator']);

    this.detectChanges();
  }

  deselectItem() {
    this.view.task = null;
  }

  detectChanges(event: any = null) {

  }

  dismiss(data: any = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any = null) {
    this.loadStore(true)
    .then(() => {

      delete this.view.templates_backup;
      
      if(event) {
        event.target.complete();
      }
      
      this.runSearch();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
      if(event) {
        event.target.complete();
      }
      this.detectChanges();
    });
  }

  import(item: any) {
    console.log('import view', this.view);

    switch(this.view.segment) {
      case 'shortcodes':
        return this.importShortcode(item);
      case 'tasks':
        return this.importTask(item);
    }

  }

  async importShortcode(shortcode: aiShortcode) {
    console.log('importShortcode', shortcode);

    this.ai.importShortcode(shortcode.uid)
    .then((response: any) => {
      console.log('import task response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async importTask(task: aiTask) {
    console.log('importTask', task);

    this.ai.importTask(task.uid)
    .then((response: any) => {
      console.log('import task response', response);
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  importSelected() {
    console.log('importSelected', this.view.selectedItems);

    if(!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.selectedItems.forEach((item: any, index: number) => {
      setTimeout(async () => {
        try {
            let importItem: any = await this.import(item);
            console.log('importItem', importItem);
        } catch(e) {
          console.warn('importing item failed', e);
        }
      }, (index * 50));
    });

  }

  expand(task: any) {
    task.expanded = !task.expanded;

    // set current preview to this task
    if(!!task.expanded) {
      this.view.task = task;
    }

  }

  async loadIndustries(blForceRefresh: boolean = false) {
    try {
      this.view.industries = await this.ai.getAIStoreIndustries(blForceRefresh);
    } catch(e) {
      console.warn('loading job_titles failed', e);
    }
  }

  async loadJobTitles(blForceRefresh: boolean = false) {
    try {
      this.view.job_titles = await this.ai.getAIStoreJobTitles(blForceRefresh);
    } catch(e) {
      console.warn('loading job_titles failed', e);
    }
  }

  loadShortcodes(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.tasks = JSON.parse(JSON.stringify(this.view.placeholder));
      this.view.loading = true;
        
      this.ai.getCustomShortcodes(blForceRefresh, {
        project_uid: 0,
        public: true,
        user_uid: 0,
      })
      .then((shortcodes: aiShortcode[]) => {
        console.log('ai store: shortcodes', shortcodes);

        this.view.loading = false;
        this.view.shortcodes = shortcodes;

        resolve(shortcodes);
      })
      .catch((error: any) => {
        this.view.loading = false;
        reject(error);
      });
    });
  }

  async loadStore(blForceRefresh: boolean = false) {
    try {
      await this.loadShortcodes(blForceRefresh);
      await this.loadTasks(blForceRefresh);
  
      this.calcItems();
    } catch(e) {
      this.events.publish('error', e);
    }
  }

  loadTasks(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.tasks = JSON.parse(JSON.stringify(this.view.placeholder));
      this.view.loading = true;
        
      this.ai.getTasks(blForceRefresh, {
        project_uid: 0,
        public: true,
        user_uid: 0,
      })
      .then((tasks: aiTask[]) => {
        console.log('ai store: tasks', tasks);

        this.view.loading = false;
        this.view.tasks = tasks;

        resolve(tasks);
      })
      .catch((error: any) => {
        this.view.loading = false;
        reject(error);
      });
    });
  }

  ngOnInit() {
    this.calcViewVars();

    this.loadIndustries();
    this.loadJobTitles();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    this.loadStore();
  }

  onItemChecked(task: any) {

    this.view.selectedItems = this.view.tasks.filter((_task: any) => {
      return _task.checked;
    });

    // set current preview to this task
    this.view.task = task;

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any = null) {
  }

  onSelectionActionChanged(event: any = null) {
    console.log('onSelectionActionChanged', this.view.selectionAction);

    switch(this.view.selectionAction) {
      case 'import':
        this.importSelected();
        break;
    }
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch(e) {
      console.error('firing toolbar search failed', e);
    }
  }

  segmentChanged() {
    this.calcItems();
  }

  thumbnailLoadingFailed(task: aiTask) {
    task.photo = this.fallbackImg;
  }
  
  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}
