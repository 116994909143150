import { Component, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { StablediffusionService } from "src/app/services/media/stablediffusion.service";
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-ai-model',
  templateUrl: './ai-model.page.html',
  styleUrls: ['./ai-model.page.scss'],
})
export class AiModelPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  @ViewChild('infoPopover') infoPopover;

  appConfig: pipelineAppConfig;

  cards: any = {
    distribution: {
      open: true,
    },
    general: {
      open: true,
    },
    media: {
      open: true,
    },
    sources: {
      open: true,
    },
  };

  fallbackImg: string = './assets/img/fallback.webp';

  isInfoPopoverOpen: boolean = false;

  media: any;

  mediaList: any;

  search: any = {
    itemsKey: 'model',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  state: any = {};

  view: any = {
    base_models: [],
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    instances: [],
    input: '',
    introCard: {
      uid: 'ai_model_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      subtitle: 'ai_model_top_card_subtitle',
      text: 'ai_model_top_card_text',
      title: 'ai_model_top_card_title',
    },
    media: {},
    mediaList: [],
    model: {},
    multiple: true,
    output: '',
    providers: [
      {
        name: 'getgenius',
        photo: './assets/img/icon.webp',
        uid: 'getgenius',
      },
      {
        name: 'integration_google_colab',
        photo: './assets/img/integrations/icons/google.webp',
        uid: 'google_colab',
      },
      {
        name: 'integration_huggingface',
        photo: './assets/img/integrations/icons/huggingface.webp',
        uid: 'huggingface',
      },
      {
        name: 'integration_lambdalabs',
        photo: './assets/img/integrations/icons/lambdalabs.webp',
        uid: 'lambdalabs',
      },
      {
        name: 'integration_microsoft_azure',
        photo: './assets/img/integrations/icons/microsoft_azure.webp',
        uid: 'microsoft_azure',
      },
    ],
    route: 'ai/model',
    selectionOptions: [
      {
        icon: 'trash-outline',
        label: 'delete',
        uid: 'delete',
      }
    ],
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    tab: 'model',
    tabs: [
      {
        icon: 'bulb-outline',
        name: 'recommendations',
        uid: 'model',
      },
      {
        icon: 'image-outline',
        name: 'assets',
        uid: 'assets',
      },
      {
        icon: 'film-outline',
        name: 'creatives',
        uid: 'creatives',
      },
      {
        icon: 'newspaper-outline',
        name: 'posts',
        uid: 'posts',
      },
    ],
    title: 'ai_model',
    types: [
      {
        label: 'text_classification',
        uid: 'text_classification',
      },
      {
        label: 'text_to_audio',
        uid: 'text_to_audio',
      },
      {
        label: 'text_to_image',
        uid: 'text_to_image',
      },
      {
        label: 'text_to_text',
        uid: 'text_to_text',
      },
      {
        label: 'translate',
        uid: 'translation',
      },
      {
        label: 'image_to_image',
        uid: 'image_to_image',
      },
      {
        label: 'object_detection',
        uid: 'object_detection',
      },
    ],
    viewType: 'grid',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sd: StablediffusionService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  build() {

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      left: (window.innerWidth > 768 ? 3 : 12),
      right: (window.innerWidth > 768 ? 9 : 12),
    };

    if (!!this.media) {
      this.view.media = this.media;
    }

    if (!!this.mediaList) {
      this.view.mediaList = this.mediaList;
    }

    console.log('ai-model: calcViewVars', this.view);
  }

  dismiss() {
    this.modalService.dismiss();
  }

  doRefresh(event: any = null) {
    this.loadModel(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });
  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadModel()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  loadInstances(blForceRefresh: boolean = false) {
    this.sd.getAvailableInstances({}, blForceRefresh)
      .then((instances: any[]) => {
        this.view.instances = (instances || []);
      })
      .catch((error: any) => {
        console.warn('loading models failed', error);
      });
  }

  loadModel(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;

      /*
      this.ai.getModelByUid(blForceRefresh)
      .then((model: aiModel[]) => {
        this.view.loading = false;
        this.view.model = model;

        resolve(model);
      })
      .catch((error: any) => {
        this.view.loading = false;
        reject(error);
      });
      */
    });
  }

  async loadPlatforms() {
    return new Promise((resolve, reject) => {
      this.integrations.getAvailable()
      .then((integrations: integration[]) => {
        this.view.availableIntegrations = (integrations || []);
        resolve(this.view);  
      })
      .catch(reject);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();

    if (!!this.view.project && !!this.view.project.uid) {
      this.loadInstances();
    }
  }

  ngOnInit() {
    this.loadProject();
    this.calcViewVars();

    this.view.provider = this.view.providers[0];

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    this.loadPlatforms()
    .catch((error: any) => {
      console.warn('loading integrations failed', error);
    });
  }

  onItemChecked(model: aiModel) {

    this.view.selectedItems = this.view.model.filter((_model: aiModel) => {
      return _model.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onInputChanged(event: any = null) {
    console.log('onInputChanged', event);
  }

  onInstancesChanged(event: any = null) {
    console.log('onInstancesChanged', event);
  }

  onIntegrationsChanged(event: any = null) {
    console.log('onIntegrationsChanged', event);
  }

  onProviderChanged(event: any = null) {
    console.log('onProviderChanged', event);
  }

  onSearchChanged(searchOptions: any = null) {
    console.log('onSearchChanged', searchOptions);
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;
  }

  update() {

  }

  viewModeChanged(event: any = null) {
    this.calcViewVars();
  }

}