import { Injectable } from '@angular/core';
import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class StablediffusionService {

  constructor(
    private AppCMS: AppcmsService,
  ) {
  }

  getAvailableInstances(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('stablediffusion', options, ['instances'], {}, blForceRefresh);
  }

  getQueue(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      "stablediffusion",
      options,
      ["queue"],
      {},
      blForceRefresh
    );
  }

  imageToImage(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToImage'], {}, blForceRefresh);
  }

  imageToText(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToText'], {}, blForceRefresh);
  }

  imageToVideo(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToVideo'], {}, blForceRefresh);
  }

  textToImage(input: string, options: any = {}, blForceRefresh: boolean = false) {
    options.input = `${input}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['textToImage'], {}, blForceRefresh);
  }

  textToVideo(input: string, options: any = {}, blForceRefresh: boolean = false) {
    options.input = `${input}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['textToVideo'], {}, blForceRefresh);
  }

  upscale(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.inputFile = `${url}`;
    options.outputFile = `${url}`;

    return this.AppCMS.loadPluginData('stablediffusion', options, ['upscale'], {}, blForceRefresh);
  }

}