<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <ion-button (click)="dismiss()" [hidden]="view.isModal === false">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="create()" color="primary" shape="round" fill="solid" icon-only
        *ngIf="!!newsletter && !newsletter.uid && !!view.startManually">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="update()" color="primary" shape="round" fill="solid" icon-only
        *ngIf="!!newsletter && !!newsletter.uid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <ion-toolbar class="ion-no-border container second-toolbar">

    <!-- Mobile back button -->
    <ion-buttons slot="start" *ngIf="!view.isDesktop && !!view.executed">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title size="large" [innerHTML]="'edit_newsletter'|translate"></ion-title>

    <!-- Mobile preview button -->
    <ion-buttons slot="end" *ngIf="!view.isDesktop && !view.executed">
      <ion-button (click)="preview()">
        <ion-icon name="eye-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Create newsletter intro mode -->
  <div class="create-newsletter-intro-wrapper" *ngIf="!!newsletter && !newsletter.uid"
    [hidden]="view.startManually || (!!newsletter && !!newsletter.uid)">
    <div class="container">

      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'create_newsletter_ai_helper_text'|translate"></ion-card-title>
        </ion-card-header>

        <ion-grid>
          <ion-row>

            <ion-col [size]="view.isDesktop ? 8 : 12">
              <ion-textarea rows="1" [(ngModel)]="view.createNewsletterInput" [disabled]="view.loading"
                [placeholder]="'create_newsletter_ai_helper_input_placeholder'|translate"></ion-textarea>
            </ion-col>

            <ion-col [size]="view.isDesktop ? 4 : 12">

              <ion-spinner [hidden]="!view.loading"></ion-spinner>

              <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
                [disabled]="!view.createNewsletterInput || !view.createNewsletterInput.length">
                <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'generate'|translate"></ion-label>
              </ion-button>

            </ion-col>

          </ion-row>

          <ion-row>
            <ion-col>
              <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear">
                <ion-icon name="create-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'create_newsletter_manually'|translate"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>

        </ion-grid>

      </ion-card>

    </div>
  </div>

  <!-- Newsletter edit mode -->
  <div class="container">

    <ion-grid>

      <ion-row>

        <!-- sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.isDesktop && !!view.executed">

          <!-- General -->
          <ion-card>

            <ion-card-header>

              <ion-card-title (click)="toggleCard('general')" [innerHTML]="'general'|translate"></ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('general')" icon-only color="dark" fill="clear">
                <ion-icon [name]="cards.general.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list lines="none" [hidden]="!cards.general.open">

              <ion-item>
                <ion-icon name="eye-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'name'|translate" position="stacked"></ion-label>
                <ion-input type="text" name="name" [(ngModel)]="newsletter.title"
                  [placeholder]="'name'|translate"></ion-input>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- ai styles -->
          <pipeline-ai-style-picker [(view)]="view"></pipeline-ai-style-picker>

          <!-- Media -->
          <pipeline-media-picker-card [(item)]="newsletter" [(items)]="view.mediaList" [(view)]="view"
            (onMediaChanged)="onMediaChanged($event)"></pipeline-media-picker-card>

          <!-- Target groups -->
          <pipeline-target-groups-picker></pipeline-target-groups-picker>

          <!-- Call to action -->
          <ion-card>

            <ion-card-header>

              <ion-card-title (click)="toggleCard('cta')" [innerHTML]="'call_to_action'|translate"></ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('cta')" icon-only color="dark" fill="clear">
                <ion-icon [name]="cards.cta.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-searchbar [(ngModel)]="view.ctaInput" [hidden]="!cards.cta.open"
              [placeholder]="'newsletter_cta_input_placeholder'|translate"
              (ionInput)="validateCTAInput()"></ion-searchbar>

            <ion-grid [hidden]="!cards.cta.open">

              <ion-row>

                <ion-col *ngFor="let type of view.newsletterTypes" size="6">

                  <ion-button lines="none" [fill]="view.newsletterType === type.uid ? 'outline' : 'clear'"
                    color="primary" expand="block" (click)="useNewsletterType(type)">
                    <ion-icon [name]="type.icon" slot="start"></ion-icon>
                    <ion-label class="ion-text-wrap">
                      <h2 [innerHTML]="type.name|translate"></h2>
                    </ion-label>
                  </ion-button>

                </ion-col>

              </ion-row>
            </ion-grid>

          </ion-card>

          <!-- Media -->
          <pipeline-media-picker-card [(item)]="view.media" [(items)]="view.mediaList" [(view)]="view"
            [hidden]="!view.executed"></pipeline-media-picker-card>

          <!-- Templates (If text overlay is set)-->
          <ion-card *ngIf="!!view.templates && !!view.templates.length">

            <ion-card-header>

              <ion-card-title (click)="toggleCard('templates')">
                <span [innerHTML]="'templates'|translate"></span>
              </ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('templates')" icon-only color="dark" fill="clear">
                <ion-icon [name]="cards.templates.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-searchbar [(ngModel)]="view.templateSearch" [hidden]="!cards.templates.open"
              [placeholder]="'search_media_templates'|translate" (ionInput)="searchTemplates()"></ion-searchbar>

            <ion-radio-group [(ngModel)]="view.template" [hidden]="!cards.templates.open">

              <ion-grid class="templates-grid">
                <ion-row>
                  <ion-col size="6" *ngFor="let template of view.templates; let i = index; trackBy:trackItems">

                    <ion-card [class.active]="!!view.template && (template.uid === view.template.uid)">

                      <div class="iframe-wrapper" *ngIf="!!template.preview">
                        <iframe [srcdoc]="template.preview" (click)="loadTemplate(template)"></iframe>
                      </div>

                      <ion-thumbnail *ngIf="!template.preview" (click)="loadTemplate(template)">
                        <ion-img [src]="template.photo" (ionError)="thumbnailLoadingFailed(template)"></ion-img>
                      </ion-thumbnail>

                      <ion-item lines="none">

                        <ion-radio [value]="template" slot="start" (click)="loadTemplate(template)"></ion-radio>

                        <ion-label class="ion-text-wrap" (click)="loadTemplate(template)">
                          <h3 [innerHTML]="template.name"></h3>
                        </ion-label>

                      </ion-item>

                    </ion-card>

                  </ion-col>
                </ion-row>
              </ion-grid>

            </ion-radio-group>

          </ion-card>

          <!-- Look and feel -->
          <ion-card>

            <ion-card-header>

              <ion-card-title (click)="toggleCard('look_and_feel')"
                [innerHTML]="'look_and_feel'|translate"></ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('look_and_feel')" icon-only color="dark" fill="clear">
                <ion-icon [name]="cards.look_and_feel.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list [hidden]="!cards.look_and_feel.open">

              <!--
              <ion-item *ngIf="!!view.templates && !!view.templates.length">
                <ion-select [label]="'template'|translate" [placeholder]="'choose'|translate" labelPlacement="floating"
                  (ionChange)="templateChanged()" [(ngModel)]="view.template" [okText]="'okay' | translate"
                  [cancelText]="'cancel' | translate">
                  <ion-select-option *ngFor="let template of view.templates"
                    [innerHTML]="('template'|translate) + ' #' + template.name" [value]="template"></ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item>

                <ion-thumbnail slot="start" (click)="uploadAttachment('logo')">
                  <ion-img [src]="newsletter.settings.branding.logo || fallbackImg" (ionError)="attachmentPreviewFailed('logo')"></ion-img>
                </ion-thumbnail>

                <ion-label position="stacked" [innerHTML]="'logo'|translate"></ion-label>
                <ion-input type="text" [(ngModel)]="newsletter.settings.branding.logo" (ionChange)="onAttachmentUrlPasted('logo')" [placeholder]="'pipeline_newsletter_logo'|translate"></ion-input>

              </ion-item>
              -->

              <ion-item>
                <ion-label [innerHTML]="'color_primary'|translate"></ion-label>
                <pipeline-color-picker (colorChange)="colorChanged($event, newsletter.settings.branding.colors.primar)"
                  [(color)]="newsletter.settings.branding.colors.primary"></pipeline-color-picker>
                <ion-badge slot="end" [style.background]="newsletter.settings.branding.colors.primary"
                  [innerHTML]="newsletter.settings.branding.colors.primary"></ion-badge>
              </ion-item>

              <ion-item>
                <ion-label [innerHTML]="'color_secondary'|translate"></ion-label>
                <pipeline-color-picker
                  (colorChange)="colorChanged($event, newsletter.settings.branding.colors.secondary)"
                  [(color)]="newsletter.settings.branding.colors.secondary"></pipeline-color-picker>
                <ion-badge slot="end" [style.background]="newsletter.settings.branding.colors.secondary"
                  [innerHTML]="newsletter.settings.branding.colors.secondary"></ion-badge>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- Multilingual -->
          <ion-card [class.success]="!!newsletter.language">

            <ion-card-header>

              <ion-card-title (click)="toggleCard('multilingual')">
                <span [innerHTML]="'multilingual'|translate"></span>
              </ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('multilingual')" icon-only color="dark" fill="clear">
                <ion-icon [name]="cards.multilingual.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-card-content [hidden]="!cards.multilingual.open">
              <p [innerHTML]="'newsletter_creator_multilingual_text'|translate"></p>
            </ion-card-content>

            <pipeline-languages-select [hidden]="!cards.multilingual.open"
              [(key)]="newsletter.language"></pipeline-languages-select>

          </ion-card>

        </ion-col>

        <!-- preview -->
        <ion-col class="preview" [size]="view.isDesktop ? 9 : 12" [hidden]="!view.isDesktop && !view.executed">

          <div class="loading-overlay" [hidden]="!view.loading">
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-spinner></ion-spinner>
          </div>

          <div class="newsletter-editor-wrapper" [hidden]="!newsletter || !newsletter.uid">

            <div id="blocks"></div>

            <div id="gjs" #gjs></div>

            <div id="info-panel" style="display:none"></div>

          </div>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer *ngIf="!!newsletter && !!newsletter.uid">
  <ion-toolbar class="container ion-no-border">

    <!-- ai prompt toolbar -->
    <ion-card class="aiPromptCard">

      <form (ngSubmit)="runAiPrompt()">

        <ion-item lines="none">

          <ion-textarea name="aiPrompt" #aiPromptnewsletterInput rows="2" [(ngModel)]="view.aiPrompt"
            [disabled]="!!view.loading" [placeholder]="'ai_prompt_newsletter_modification'|translate"></ion-textarea>

          <ion-button (click)="runAiPrompt()" expand="block" [disabled]="view.loading"
            [size]="view.isDesktop ? 'medium' : 'small'" color="primary" shape="round">
            <ion-label [innerHTML]="'continue' | translate" [hidden]="!view.isDesktop"></ion-label>
            <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
          </ion-button>

        </ion-item>

      </form>

    </ion-card>

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>