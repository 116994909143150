<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <ion-button (click)="dismiss()" [hidden]="view.isModal === false">
          <ion-icon name="close"></ion-icon>
      </ion-button>
      
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="create()" [disabled]="!view.shortcode.name || !view.shortcode.input" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <ion-card>

      <ion-item>
        <ion-icon slot="start" [name]="view.shortcode.icon" (click)="chooseIcon()"></ion-icon>
        <ion-label position="floating" [innerHTML]="'ai_shortcode_name'|translate"></ion-label>
        <ion-input [(ngModel)]="view.shortcode.name" [placeholder]="'ai_shortcode_name_placeholder'|translate"></ion-input>
      </ion-item>

      <ion-item lines="none">
        <ion-icon slot="start" [name]="view.shortcode.promptType && !!view.shortcode.promptType.icon ? view.shortcode.promptType.icon : 'build-outline'"></ion-icon>
        <ion-label [innerHTML]="'prompt_type'|translate"></ion-label>

        <ion-select [placeholder]="'select'|translate" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [(ngModel)]="view.shortcode.promptType" (ionChange)="onShortcodePromptTypeChanged()">
          <ion-select-option *ngFor="let promptType of view.promptTypes; trackBy:trackItems" [innerHTML]="promptType.name|translate" [value]="promptType.uid"></ion-select-option>
        </ion-select>
        
      </ion-item>

    </ion-card>

    <ion-card>

      <ion-item lines="none">
        <ion-label [innerHTML]="'ai_shortcode_input_label'|translate"></ion-label>
      </ion-item>

      <ion-card-content [hidden]="view.tab != 'custom'">
        <ion-textarea rows="12" [(ngModel)]="view.shortcode.input" [disabled]="!view.shortcode.name" (ionChange)="onShortcodeInputChanged($event)" (ionInput)="onShortcodeInputChanged($event)" [placeholder]="'ai_shortcode_input_placeholder'|translate"></ion-textarea>
      </ion-card-content>

    </ion-card>

    <ion-card>

      <ion-item lines="none">
        <ion-label [innerHTML]="'variables'|translate"></ion-label>
      </ion-item>

      <ion-card-content *ngIf="!!view.variables">
        <ion-card *ngFor="let variable of view.variables; let iVariable = index; trackBy:trackItems">
          <ion-grid>
            <ion-row>
  
              <ion-col size="4">
                <ion-item lines="none">
                  <ion-input type="text"[(ngModel)]="variable.input_variable" [placeholder]="'input_variable'|translate" [disabled]="!!variable.required"></ion-input>
                </ion-item>
              </ion-col>

              <ion-col size="3">
                <ion-item lines="none">
                  <ion-select [(ngModel)]="variable.action" [placeholder]="'variable_action'|translate" [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
                    <ion-select-option value="auto" [innerHTML]="'auto_detection'|translate"></ion-select-option>
                    <ion-select-option value="database" [innerHTML]="'database'|translate"></ion-select-option>
                    <ion-select-option value="fetch_url" [innerHTML]="'fetch_url'|translate"></ion-select-option>
                    <ion-select-option value="text_completion" [innerHTML]="'text_completion'|translate"></ion-select-option>
                    <ion-select-option value="text_to_image" [innerHTML]="'text_to_image'|translate"></ion-select-option>
                    <ion-select-option value="text_to_video" [innerHTML]="'text_to_video'|translate"></ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>

              <ion-col size="3">
                <ion-item lines="none">
                  <ion-input type="text" [(ngModel)]="variable.output_variable" [placeholder]="'output_variable'|translate" [disabled]="!variable.action"></ion-input>
                </ion-item>
              </ion-col>
              
              <ion-col size="2">
                <ion-button fill="clear" color="danger" size="block" icon-only (click)="deleteVariable(iVariable)" [disabled]="!!variable.required">
                  <ion-icon name="trash-outline"></ion-icon>
                </ion-button>
              </ion-col>
  
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-card-content>

      <ion-card-content>

        <ion-button (click)="addVariable()" [disabled]="!view.shortcode.name" fill="clear">
          <ion-icon name="add-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'add'|translate"></ion-label>
        </ion-button>

      </ion-card-content>

    </ion-card>

  </div>

</ion-content>