import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { UserService } from 'src/app/services/core/user.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PostsAdminService {

  _detailItem: post | null;

  DRAFTS_POSTS_KEY: string = 'post_drafts';

  key: string = 'postsAdmin_posts';

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private creators: CreatorsService,
    private posts: PostsService,
    private userService: UserService,
  ) {
  }

  appendExternalPostsBySourceUid(blogSourceUid: number, posts: post[], blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.posts.getPostsBySourceUid(blogSourceUid, blForceRefresh)
        .then((sourcePosts: post[]) => {

          sourcePosts.forEach((post: post) => {
            post.type = 'external';
          });

          resolve(
            (posts || []).concat((sourcePosts || []))
          );
        })
        .catch(reject);
    });
  }

  async delete(postId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      post: {
        uid: postId,
      },
    }, ['posts', 'deleteDraft']);
  }

  async chooseFolder(options: any = {}) {

    const chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getFolders({}, true),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  createFolder(folder: mediaFolder) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        folder: folder,
      },
      ["posts", "folders", "create"]
    );
  }

  deleteFolder(folderId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        folder_uid: folderId,
      },
      ["posts", "folders", folderId, "delete"]
    );
  }

  detailItem(item: post | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  executeMove(postId: number, folderId: number) {
    console.log('executeMove', postId, folderId);

    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        source: postId,
        target: folderId,
      },
      ["posts", "folders", "move"],
      {},
      true,
    );
  }

  getAll(options: any = {}, blForceRefresh: boolean = false,) {
    return new Promise(async (resolve, reject) => {
      options.blogSourceUid = options.blogSourceUid || this.userService.getClassification('blogSourceUid');

      this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
        uid: this.userService.getUid(),
      }), ['posts', 'user'], {}, blForceRefresh)
        .then(async (posts: post[]) => {

          if (options.blogSourceUid) {
            this.appendExternalPostsBySourceUid(
              options.blogSourceUid, posts
            )
              .then((posts: post[]) => {

                if (posts && posts.length) {
                  posts.forEach((post: post) => {
                    post = this.posts.getFullPost(post);
                    post.timestamp_formatted = moment(post.date_gmt || post.timestamp).format('DD.MM.YYYY HH:mm');
                    post.type = post.type || 'draft';
                  });
                }

                resolve(posts);
              })
              .catch((error: any) => {
                console.warn('> appending blog posts by blog uid failed', error);
                resolve(posts);
              });
          } else {

            if (posts && posts.length) {
              posts.forEach((post: post) => {
                post = this.posts.getFullPost(post);
                post.timestamp_formatted = moment(post.date_gmt || post.timestamp).format('DD.MM.YYYY HH:mm');
                post.type = post.type || 'draft';
              });
            }

            resolve(posts);
          }

        })
        .catch(reject);
    });
  }

  getByFolder(
    folderId: number,
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {},
      ["posts", "folders", folderId],
      params,
      blForceRefresh
    );
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {

    if (!options.hasOwnProperty("include_items")) {
      options.include_items = false;
    }

    return this.AppCMS.loadPluginData("pipeline", options, ["posts", "folders"], params, blForceRefresh);
  }

  async getSettings(blForceRefresh: boolean = false, options: any = {}) {
    return this.creators.getSettings('posts', blForceRefresh, options);
  }

  moveFolder(posts: post | post[], options: any = {}) {
    return new Promise(async (resolve, reject) => {
      console.log('moveFolder', posts);

      this.chooseFolder()
        .then((chooseResponse: chooseResponse) => {

          const target: mediaFolder =
            chooseResponse && chooseResponse.data && chooseResponse.data.item
              ? chooseResponse.data.item
              : [];

          console.log('target', target);

          if (!!target && !!target.uid) {
            if (!!(posts as post).uid) {
              this.executeMove((posts as post).uid, target.uid)
                .then(resolve)
                .catch(reject);
            } else if (!!posts && !!(posts as post[]).length) {
              (posts as post[]).forEach(
                (post: post, index: number) => {
                  this.executeMove((post as post).uid, target.uid)
                    .then((response: any) => {
                      if (index === (posts as post[]).length - 1) {
                        resolve(response);
                      }
                    })
                    .catch((error: any) => {
                      console.warn("> single item move failed", error);

                      if (index === (posts as post[]).length - 1) {
                        resolve({
                          success: true,
                        });
                      }
                    });
                }
              );
            }
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  setCurrentBlogSource(url: string) {
    console.log('> setCurrentBlogSource', url);
  }

  async submitForReview(post: post) {
    post.type = 'review';

    return this.AppCMS.loadPluginData('pipeline', {
      post: post,
    }, ['posts', 'submitForReview']);
  }

  async submitPost(_post: post) {
    return new Promise((resolve, reject) => {
      let post: any = JSON.parse(JSON.stringify(_post)),
        _moment = moment(new Date());

      post.timestamp = _moment.format("YYYY-MM-DD HH:mm:ss");
      post.timestamp_formatted = _moment.format('DD.MM.YYYY HH:mm');

      post.post_date = moment(post.post_date || post.timestamp).format("YYYY-MM-DD HH:mm:ss");
      post.post_date_gmt = moment((post.post_date || post.post_date_gmt) || post.timestamp).format("YYYY-MM-DD HH:mm:ss");
      post.date_gmt = post.post_date;

      post.active = !!post.active;
      post.post_type = post.post_type || 'draft';
      post.user = post.user || this.userService.getUid();

      delete post.checked;
      delete post.end;
      delete post.start;

      console.log('> posts-admin: submit post', post);

      this.AppCMS.loadPluginData('pipeline', {
        post: post,
      }, ['posts', 'create']).then(resolve).catch(reject);
    });
  }

  updatePost(post: post) {
    return this.posts.updatePost(post);
  }

}
