import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";

import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'pipeline-lottie-loading-stage',
  templateUrl: './lottie-loading-stage.component.html',
  styleUrls: ['./lottie-loading-stage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LottieLoadingStageComponent implements OnDestroy, OnInit {
  @Input() options: any;

  fallbackAvatarImg: string = 'https://web.pipeline.page/assets/img/avatars/1.jpg';
  fallbackImg: string = 'https://web.pipeline.page/assets/img/fallback.webp';

  proxyUrl: string = 'https://app.pipeline.page/api/searchengine/proxy.raw?url=';

  template: mediaTemplate = {};

  view: any = {
    avatar_src: 'https://web.pipeline.page/assets/img/avatars/1.jpg',
    bg_src: 'https://web.pipeline.page/assets/img/fallback.webp',
    logo_src: '',
    phase: 2,
  };

  constructor(
    private events: EventsService,
  ) {

  }
  
  animationCreated(animationItem: AnimationItem): void {
  }

  applyMetaData() {
    this.template = this.template || {};
    this.template.config = this.template.config || {};
    this.template.config._meta = this.template.config._meta || {};
  }

  configReady() {

  }

  dataReady() {

  }

  destroy(event: any = null) {
    //console.warn('destroy', event);
  }

  domLoaded() {

  }

  error(event: any = null) {
    console.warn('error', event);
  }

  initEvents() {
  }

  ionViewWillEnter() {
    this.applyMetaData();
  }

  loadLottiePreview() {
    this.options = this.options || {};
    this.options.render_path = this.options.url || "https://assets10.lottiefiles.com/packages/lf20_g1pduE.json"; //'https://assets5.lottiefiles.com/packages/lf20_qlhfsbyApx.json';
    
    this.updateAnimation();
  }

  ngOnChanges(changes: any) {
    if(!!changes && !!changes.options && !!changes.options.currentValue && !!changes.options.currentValue.url) {
      this.options.url = changes.options.currentValue.url;
    }
  }

  ngOnDestroy() {
    if(!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();
    this.applyMetaData();

    this.loadLottiePreview();
  }

  updateAnimation(): void {
    this.options = {
      ...this.options,
      path: this.options.render_path,
    };
  }

}