import { Component, OnInit, ViewChild } from '@angular/core';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ProjectsService } from 'src/app/services/core/projects.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/header/header-search-toolbar/header-search-toolbar.component';

import { AiModelPage } from '../ai-model/ai-model.page';
import { AiStorePage } from '../ai-store/ai-store.page';

@Component({
  selector: 'app-ai-models',
  templateUrl: './ai-models.page.html',
  styleUrls: ['./ai-models.page.scss'],
})
export class AiModelsPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  fallbackImg: string = './assets/img/fallback.webp';

  search: any = {
    itemsKey: 'models',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  state: any = {};

  view: any = {
    categories: [],
    categoryIcons: {
      IMG2IMG: 'image-outline',
      IMG2TXT: 'text-outline',
      IMG2VID: 'film-outline',
      TXT2IMG: 'image-outline',
      TXT2TXT: 'text-outline',
      TXT2VID: 'film-outline',
      TXT2WAV: 'mic-outline',
      WAV2TXT: 'recording-outline',
    },
    filters: {
      category: 'all',
      provider: 'all',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    introCard: {
      uid: 'ai_models_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      subtitle: 'ai_models_top_card_subtitle',
      text: 'ai_models_top_card_text',
      title: 'ai_models_top_card_title',
    },
    mode: 'view',
    multiple: true,
    output: '',
    providers: [],
    route: 'ai/models',
    selectionOptions: [
      {
        icon: 'trash-outline',
        label: 'delete',
        uid: 'delete',
      }
    ],
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'ai_models',
    viewType: 'grid',
  };

  constructor(
    private ai: AiToolsService,
    
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private tools: ToolsService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async add(event: any = null) {

    const modal: any = await this.modalService.create({
      component: AiModelPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcViewVars() {
    this.view.isModal = this.modalService.isModal();
    this.view.mode = (this.view.isModal ? 'pick' : 'view');

    this.calcColSize();
  }

  delete(model: aiModel) {

  }

  deleteSelected() {

    if (!this.view.selectedItems) {
      return false;
    }

    new Promise((resolve, reject) => {
      let i: number = 0;

    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  dismiss(data: any = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any = null) {
    this.loadModels(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  filterCategory(category: any) {
    this.view.filters.category = category.uid;

    this.view.models.forEach((model: any) => {
      model.hidden = (category.uid !== 'all') && `${model.category || ''}`.toLowerCase() !== this.view.filters.category;
    });
  }

  filterProvider(provider: any) {
    this.view.filters.provider = provider.uid;

    this.view.models.forEach((model: any) => {
      model.hidden = (provider.uid !== 'all') && `${model.provider || ''}`.toLowerCase() !== this.view.filters.provider;
    });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadModels()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  loadModels(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;

      this.ai.getAvailableModels({}, blForceRefresh)
        .then((models: aiModel[]) => {
          this.view.loading = false;
          this.view.models = models;

          let categoriesStrings: string[] = [],
            categories: any[] = [],
            providerStrings: string[] = [],
            providers: any[] = [];

          models.forEach((model: any) => {

            let categoryId: string | null = (!!model.category ? `${model.category || ''}`.toLowerCase() : null),
              providerId: string | null = (!!model.provider ? `${model.provider || ''}`.toLowerCase() : null);

            if (!!categoryId && (categoriesStrings.indexOf(categoryId) === -1)) {
              categories.push({
                icon: this.view.categoryIcons[model.category],
                name: model.category,
                uid: categoryId,
              });
              categoriesStrings.push(categoryId);
            }

            if (!!providerId && (providerStrings.indexOf(providerId)) === -1) {
              providers.push({
                name: model.provider,
                photo: model.photo,
                uid: providerId,
              });
              providerStrings.push(providerId);
            }

          });

          const allItem: any = { icon: 'list-outline', name: 'all', uid: 'all' };

          this.view.categories = [allItem].concat(categories);
          this.view.providers = [allItem].concat(providers);

          resolve(models);
        })
        .catch((error: any) => {
          this.view.loading = false;
          reject(error);
        });
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadProject();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  onItemChecked(model: aiModel) {

    this.view.selectedItems = this.view.models.filter((_model: aiModel) => {
      return _model.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any = null) {
  }

  onSelectionActionChanged(event: any = null) {

    switch (this.view.selectionAction) {
      case 'delete':
        this.deleteSelected();
        break;
    }

  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  async store() {

    const modal: any = await this.modalService.create({
      component: AiStorePage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  submitSelected(event: any = null) {
    console.log('submitSelected', this.view.selectedItems);

    return this.dismiss({
      item: (!!this.view.selectedItems && !!this.view.selectedItems[0] ? this.view.selectedItems[0] : null),
      items: (this.view.selectedItems || []),
    });
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  use(model: aiModel) {

  }

  viewModeChanged(event: any = null) {
    this.calcViewVars();
  }

}
