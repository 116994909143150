<ion-item class="language-picker" lines="none" (click)="pick()" [class.allow-mini]="!!options && !!options.allowMini">
  <ion-icon [name]="icon || 'language-outline'" slot="start" color="primary"></ion-icon>

  <ion-label class="ion-text-wrap" [hidden]="!view.isDesktop && (!!options && !!options.allowMini)" [innerHTML]="(label || (!!multiple ? 'languages' : 'language'))|translate"></ion-label>

  <ion-note slot="end" *ngIf="!multiple" [innerHTML]="(!!key ? ('language_' + key) : 'select')|translate"></ion-note>

  <div *ngIf="!!multiple && !!key && !!key.length">
    <ion-chip slot="end" *ngFor="let item of key" [hidden]="!item" [innerHTML]="('language_' + item)|translate"></ion-chip>
  </div>

</ion-item>