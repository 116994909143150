import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { IntroCardComponent } from './intro-card.component';

import { SharedModule } from 'src/app/shared.module';

import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';

import { SplineViewerComponentModule } from 'src/app/components/spline/spline-viewer/spline-viewer.component.module';

@NgModule({
  declarations: [
    IntroCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    LottieModule,
    SharedModule,
    SplineViewerComponentModule,
  ],
  exports: [
    IntroCardComponent,
  ]
})
export class IntroCardComponentModule { }