import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { LanguageService } from "src/app/services/core/language.service";
import { ProjectsService } from "src/app/services/core/projects.service";
import { SettingsService } from "src/app/services/core/settings.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from "src/app/services/core/user.service";

@Component({
  selector: 'pipeline-languages-select',
  templateUrl: './languages-select.component.html',
  styleUrls: ['./languages-select.component.scss']
})
export class LanguagesSelectComponent implements OnDestroy, OnInit {
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() key: string | string[];
  @Input() label: string;
  @Input() multiple: boolean;
  @Input() options: any;

  @Output() changed = new EventEmitter();

  view: any = {
    languages: [],
  };

  constructor(
    private events: EventsService,
    private language: LanguageService,
    private projects: ProjectsService,
    private settings: SettingsService,
    private tools: ToolsService,
    private user: UserService,
  ) {
    this.key = this.key || this.language.getCurrentLanguage();
  }

  calcViewVars() {
    this.view.isDesktop = this.tools.isDesktop();
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.languagesSelectProject = project;

      this.loadLanguages();
    });
  }

  async loadLanguages() {
    try {

      let languages: any = await this.language.getLanguages(),
          blAll: boolean = (!!this.options && !!this.options.all);

      if (!blAll && !!this.view && !!this.view.languagesSelectProject && !!this.view.languagesSelectProject.config && !!this.view.languagesSelectProject.config.languages && !!this.view.languagesSelectProject.config.languages.length) {
        let projectLanguages: string[] = this.view.languagesSelectProject.config.languages;

        // add main language to project languages list if set
        if (!!this.view.languagesSelectProject.language) {
          projectLanguages.push(this.view.languagesSelectProject.language);
        }

        languages = (languages || [])
          .filter((language: language) => {
            return (projectLanguages.indexOf(language.indent) !== -1);
          })

      }

      this.view.languages = languages;
      
    } catch (e) {
      this.events.publish(e);
    }
  }

  async loadProject() {
    this.view.languagesSelectProject = await this.projects.getCurrent();
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    //let currentLanguage: string = this.language.getCurrentLanguage();
    //console.log('language-select: currentLanguage', currentLanguage);
    //this.key = this.key || this.language.getCurrentLanguage();
    
    this.calcViewVars();
    this.initEvents();

    this.loadProject();
    this.loadLanguages();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  pick() {

    if (!!this.disabled) {
      return false;
    }

    const options: any = Object.assign(this.options || {}, {
      data: (this.view.languages || []).map((item: any) => {
        item.checked = (!!this.key && !!this.key.length && (this.key.indexOf(item.indent) !== -1));
        
        /*
        if(!!item.flag) {
          item.photo = item.flag;
        }
        */
       
        return item;
      }),
      multiple: !!this.multiple,
    });

    this.language.pick(options)
      .then((response: any) => {

        if (!!response && !!response.data && !!response.data.items && !!response.data.items.length && (response.data.items.length > 1)) {

          this.key = response.data.items.map((item: any) => {
            return `${item.indent}`;
          });

          this.updateLanguage();
        } else
          if (!!response && !!response.data && !!response.data.item) {
            this.key = (!!this.multiple ? [`${response.data.item.indent}`] : `${response.data.item.indent}`);
            this.updateLanguage();
          }

      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      })
  }

  updateLanguage() {

    // only sync with user account if specified
    if (!this.options || !this.options.applyToUser || !!this.multiple) {
      this.changed.emit(this.key);
      return false;
    }

    let user: user = this.user.getUser() || {};

    if (!user || !user.uid || (user.uid === -1) || !this.key) {
      return false;
    }

    user.classifications = user.classifications || {};
    user.classifications.language = this.key;

    this.user.setUser(user, true)
      .then(() => {
        this.changed.emit(this.key);
        this.settings.showUpdatedToast();
        this.events.publish('home:refresh');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}