<!-- Single media item information popover -->
<ion-popover #itemInfoPopover [isOpen]="isItemInfoPopoverOpen" (didDismiss)="isItemInfoPopoverOpen = false">
  <ng-template>
    <ion-content>

      <ion-list *ngIf="!!view.item && !!view.item.info">

        <ion-item [hidden]="!view.item.info.sd_model_name">
          <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'sd_model_name'|translate"></h3>
            <p [innerHTML]="view.item.info.sd_model_name"></p>
          </ion-label>

        </ion-item>

        <ion-item [hidden]="!view.item.info.seed">
          <ion-icon name="finger-print-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'seed'|translate"></h3>
            <p [innerHTML]="view.item.info.seed"></p>
          </ion-label>

        </ion-item>

        <ion-item [hidden]="!view.item.info.sampler_name">
          <ion-icon name="layers-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'sampler_name'|translate"></h3>
            <p [innerHTML]="view.item.info.sampler_name"></p>
          </ion-label>

        </ion-item>

        <ion-item [hidden]="!view.item.info.cfg_scale">
          <ion-icon name="flask-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'cfg_scale'|translate"></h3>
            <p [innerHTML]="view.item.info.cfg_scale"></p>
          </ion-label>

        </ion-item>

        <ion-item [hidden]="!view.item.info.denoising_strength">
          <ion-icon name="flask-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'denoising_strength'|translate"></h3>
            <p [innerHTML]="view.item.info.denoising_strength"></p>
          </ion-label>

        </ion-item>

        <ion-item [hidden]="!view.item.info.steps">
          <ion-icon name="sync-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'steps'|translate"></h3>
            <p [innerHTML]="view.item.info.steps"></p>
          </ion-label>

        </ion-item>

        <ion-item [hidden]="!view.item.instance">
          <ion-icon name="server-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'instance'|translate"></h3>
            <p [innerHTML]="view.item.instance"></p>
          </ion-label>

        </ion-item>

      </ion-list>

    </ion-content>
  </ng-template>
</ion-popover>

<!-- Popover for generic information -->
<ion-popover #infoPopover [isOpen]="isInfoPopoverOpen" (didDismiss)="isInfoPopoverOpen = false">
  <ng-template>
    <ion-content>
      <p class="ion-padding" *ngIf="!!view.infoPopoverContent" [innerHTML]="view.infoPopoverContent|translate"></p>
    </ion-content>
  </ng-template>
</ion-popover>

<!-- Create media intro mode -->
<div class="create-media-intro-wrapper" *ngIf="!!view.media && !view.media.uid && !view.startManually">
  <div class="container">

    <ion-card>

      <ion-card-header>
        <ion-card-title>
          <span [innerHTML]="'media_creator_input_mode_text'|translate"></span>
        </ion-card-title>
      </ion-card-header>

      <ion-textarea [rows]="view.isDesktop ? 1 : 2" [(ngModel)]="search.query" [disabled]="view.loading"
        [placeholder]="'media_creator_ai_prompt_placeholder'|translate"></ion-textarea>

      <!-- Types -->
      <div *ngIf="!!view.types && !!view.types.length">

        <ion-card-header>
          <ion-card-title>
            <span [innerHTML]="'media_creator_types_headline'|translate"></span>
          </ion-card-title>
        </ion-card-header>

        <ion-grid class="types-grid">
          <ion-row>

            <ion-col size="6" *ngFor="let type of view.types; let iType = index;">

              <ion-button class="types-btn" [class.active]="type.checked" size="block"
                [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                <ion-icon [name]="type.icon"></ion-icon>
                <ion-label [innerHTML]="type.name|translate"></ion-label>
              </ion-button>

            </ion-col>

          </ion-row>
        </ion-grid>

      </div>

      <ion-item lines="none" color="medium" class="info-item">
        <ion-icon name="information-circle-outline" slot="start" [hidden]="!view.isDesktop"></ion-icon>
        <ion-label class="ion-text-wrap" [innerHTML]="'media_creator_alert_video_creator_label'|translate"></ion-label>
        <ion-button (click)="videoCreator()" slot="end">
          <ion-label [innerHTML]="'try_it_out'|translate"></ion-label>
        </ion-button>
      </ion-item>

      <!--
      <ion-card-header>
        <ion-card-title>
          <span [innerHTML]="'output'|translate"></span>
        </ion-card-title>
      </ion-card-header>
      -->

      <!-- Generic aspect ratio -->
      <!--
      <ion-grid class="types-grid" [hidden]="!!view.expertMode">
        <ion-row>

          <ion-col size="4" *ngFor="let aspect_ratio of view.aspect_ratios; let iAspectRatio = index;">

            <ion-button class="types-btn" [class.active]="aspect_ratio.checked" size="block"
              [fill]="aspect_ratio.checked ? 'solid' : 'outline'" color="primary"
              (click)="toggleAspectRatio(aspect_ratio, iAspectRatio)">
              <ion-icon [name]="aspect_ratio.icon"></ion-icon>
              <ion-label [innerHTML]="aspect_ratio.name|translate"></ion-label>
            </ion-button>

          </ion-col>

        </ion-row>
      </ion-grid>
      -->

      <ion-grid>
        <ion-row>

          <ion-col [size]="view.isDesktop ? 6 : 12">

            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
              [disabled]="!search.query || !search.query.length">
              <ion-icon name="sparkles-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'generate'|translate"></ion-label>
            </ion-button>

          </ion-col>

          <ion-col [size]="view.isDesktop ? 6 : 12">
            <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear">
              <ion-icon name="create-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'create_media_manually'|translate"></ion-label>
            </ion-button>
          </ion-col>

        </ion-row>

      </ion-grid>

    </ion-card>

  </div>
</div>

<ion-grid class="media-creator-grid" *ngIf="!!view.media && !!view.aiOptions">

  <!-- Mobile bar (back button + intro card)-->
  <ion-row class="mobile-row" *ngIf="!view.isDesktop && (!!view.mediaCreatorIntroCard || (view.phase !== 'input'))"
    (click)="backToInputMode()">

    <ion-toolbar class="container second-toolbar ion-no-border">

      <!-- Mobile back bar -->
      <ion-buttons slot="start" *ngIf="view.phase !== 'input'">
        <ion-button fill="clear" color="dark">
          <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'back'|translate"></ion-label>
        </ion-button>
      </ion-buttons>

    </ion-toolbar>

    <!-- Mobile intro card-->
    <ion-toolbar class="container second-toolbar ion-no-border" *ngIf="!!view.mediaCreatorIntroCard"
      [hidden]="!!view.mediaCreatorIntroCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.mediaCreatorIntroCard"></pipeline-intro-card>
    </ion-toolbar>

  </ion-row>

  <ion-row>

    <!-- sidebar -->
    <ion-col #sidebar class="sidebar" [size]="view.isDesktop ? 3 : 12"
      [hidden]="!view.isDesktop && (view.phase !== 'input')">

      <!-- Content Types -->
      <div class="col-inner" [class.success]="!!view.hasSelectedTypes" *ngIf="!!view.types && !!view.types.length">
        <ion-card>

          <ion-card-header>

            <ion-card-title (click)="toggleCard('types')">
              <span [innerHTML]="'media_creator_types_headline'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('types')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.types.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <ion-card-content [hidden]="!cards.types.open">
            <p [innerHTML]="'media_creator_types_text'|translate"></p>
          </ion-card-content>

          <ion-grid class="types-grid" [hidden]="!cards.types.open">
            <ion-row>

              <ion-col size="6" *ngFor="let type of view.types; let iType = index;">

                <ion-button class="types-btn" [class.active]="type.checked" size="block"
                  [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                  <ion-icon [name]="type.icon"></ion-icon>
                  <ion-label [innerHTML]="type.name|translate"></ion-label>
                </ion-button>

              </ion-col>

            </ion-row>
          </ion-grid>

          <ion-item lines="none" color="medium" class="info-item" [hidden]="!cards.types.open">
            <ion-label class="ion-text-wrap"
              [innerHTML]="'media_creator_alert_video_creator_label'|translate"></ion-label>
            <ion-button (click)="videoCreator()" slot="end">
              <ion-label [innerHTML]="'try_it_out'|translate"></ion-label>
            </ion-button>
          </ion-item>

        </ion-card>
      </div>

      <!-- Input Mode -->
      <div class="col-inner" [class.success]="!!view.hasSelectedInputModes" *ngIf="!!view.types && !!view.types.length"
        [hidden]="!view.hasSelectedTypes">
        <ion-card>

          <ion-card-header>

            <ion-card-title (click)="toggleCard('inputMode')">
              <span [innerHTML]="'media_creator_input_mode_headline'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('inputMode')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.inputMode.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <ion-card-content [hidden]="!cards.inputMode.open">
            <p [innerHTML]="'media_creator_input_mode_text'|translate"></p>
          </ion-card-content>

          <ion-grid class="types-grid" [hidden]="!cards.inputMode.open">
            <ion-row>

              <ion-col size="6" *ngFor="let mode of view.inputModes; let iMode = index;">

                <ion-button class="types-btn" [class.active]="mode.checked" size="block"
                  [fill]="mode.checked ? 'solid' : 'outline'" color="primary" (click)="toggleInputMode(mode, iMode)">
                  <ion-icon [name]="mode.icon"></ion-icon>
                  <ion-label [innerHTML]="mode.name|translate"></ion-label>
                </ion-button>

              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-card>
      </div>

      <!-- Text input -->
      <div class="col-inner" [class.success]="!!search.query"
        [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList || (!view.selectedInputModesList || !view.selectedInputModesList.text)">
        <ion-card>

          <ion-card-header>

            <ion-card-title (click)="toggleCard('information')">
              <span [innerHTML]="'information'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('information')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.information.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <ion-card-content [hidden]="!cards.information.open">
            <p [innerHTML]="'media_creator_information_text'|translate"></p>
          </ion-card-content>

          <ion-list [hidden]="!cards.information.open">

            <ion-item lines="none">
              <ion-icon name="information-outline" slot="start"></ion-icon>

              <ion-textarea [rows]="view.expertMode ? 5 : 3" [label]="'input'|translate" labelPlacement="stacked"
                [autofocus]="true" (ionChange)="onInputChanged($event)" [(ngModel)]="search.query" #positivePromptInput
                id="positivePromptInput" [placeholder]="'media_creator_ai_prompt_placeholder'|translate"></ion-textarea>

            </ion-item>

            <ion-item lines="none" [hidden]="!view.expertMode">
              <ion-icon name="eye-off-outline" slot="start"></ion-icon>

              <ion-textarea rows="5" [label]="'negative_prompt'|translate" labelPlacement="stacked"
                [(ngModel)]="view.negative_prompt" #negativePromptInput id="negativePromptInput"
                [placeholder]="'negative_prompt_placeholder'|translate"></ion-textarea>

            </ion-item>

            <ion-item lines="none">

              <ion-checkbox [(ngModel)]="view.blFineTuneInput" (ionChange)="toggleFineTune()" justify="start"
                labelPlacement="end" [innerHTML]="'media_creator_fine_tune_input'|translate"
                class="ion-text-wrap"></ion-checkbox>

              <ion-button (click)="presentInfoPopover($event, 'media_creator_fine_tune_input_infotext')" icon-only
                fill="clear" color="dark" slot="end">
                <ion-icon name="information-circle-outline"></ion-icon>
              </ion-button>

            </ion-item>

          </ion-list>

        </ion-card>
      </div>

      <!-- Media -->
      <div class="col-inner" [class.success]="!!view.mediaList && !!view.mediaList.length"
        [hidden]="(!view.mediaList || !view.mediaList.length) && (!view.hasSelectedTypes || !view.selectedInputModesList || (!view.selectedInputModesList || !view.selectedInputModesList.media))">
        <pipeline-media-picker-card #mediaPickerComponent [(item)]="view.media" [(items)]="view.mediaList"
          [(view)]="view"></pipeline-media-picker-card>
      </div>

      <!-- Overlay -->
      <div class="col-inner" [class.success]="!!view.media.title"
        [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList">
        <ion-card>

          <ion-card-header>

            <ion-card-title (click)="toggleCard('overlay')">
              <span [innerHTML]="'text_overlay'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('overlay')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.overlay.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <div [hidden]="!cards.overlay.open">

            <ion-card-content>
              <p [innerHTML]="'media_creator_text_overlay_text'|translate"></p>
            </ion-card-content>

            <pipeline-media-create-form-automotive [view]="view" (onViewChanged)="onOverlayFormViewChanged($event)"
              *ngIf="view.industryUI === 'automotive'"></pipeline-media-create-form-automotive>
            <pipeline-media-create-form-default [view]="view" (onViewChanged)="onOverlayFormViewChanged($event)"
              *ngIf="!view.industryUI || view.industryUI === 'default'"></pipeline-media-create-form-default>
            <pipeline-media-create-form-dynamic [view]="view" (onViewChanged)="onOverlayFormViewChanged($event)"
              *ngIf="view.industryUI === 'dynamic'"></pipeline-media-create-form-dynamic>
            <pipeline-media-create-form-realestate [view]="view" (onViewChanged)="onOverlayFormViewChanged($event)"
              *ngIf="view.industryUI === 'realestate'"></pipeline-media-create-form-realestate>

            <ion-grid>
              <ion-row>
                <ion-col size="6">
                  <ion-button color="dark" class="default-btn" (click)="addSection()" fill="clear">
                    <ion-icon name="add-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'add'|translate" class="ion-text-wrap"></ion-label>
                  </ion-button>
                </ion-col>
                <ion-col size="6">
                  <ion-item lines="none" class="ion-text-wrap">
                    <ion-checkbox [(ngModel)]="view.media.blFineTuneInput" (ionChange)="toggleFineTune()"
                      justify="start" labelPlacement="end" [innerHTML]="'media_creator_fine_tune_input'|translate"
                      class="ion-text-wrap"></ion-checkbox>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>

          </div>

        </ion-card>
      </div>

      <!-- Styles -->
      <div class="col-inner" [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList">
        <ion-card>
          <pipeline-ai-style-picker [(view)]="view"></pipeline-ai-style-picker>
        </ion-card>
      </div>

      <!-- Output settings -->
      <div [class.success]="view.aspect_ratio" class="col-inner"
        [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList">
        <ion-card>

          <ion-card-header>

            <ion-card-title (click)="toggleCard('output')">
              <span [innerHTML]="'output'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('output')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.output.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <div [hidden]="!cards.output.open">


            <ion-card-content>
              <p [innerHTML]="'media_creator_output_text'|translate"></p>
            </ion-card-content>

            <!-- Generic aspect ratio -->
            <ion-grid class="types-grid">
              <ion-row>

                <ion-col size="4" *ngFor="let aspect_ratio of view.aspect_ratios; let iAspectRatio = index;">

                  <ion-button class="types-btn" [class.active]="aspect_ratio.checked" size="block"
                    [fill]="aspect_ratio.checked ? 'solid' : 'outline'" color="primary"
                    (click)="toggleAspectRatio(aspect_ratio, iAspectRatio)">
                    <ion-icon [name]="aspect_ratio.icon"></ion-icon>
                    <ion-label [innerHTML]="aspect_ratio.name|translate"></ion-label>
                  </ion-button>

                </ion-col>

              </ion-row>
            </ion-grid>

            <ion-item lines="none">
              <ion-icon [hidden]="!view.isDesktop" name="albums-outline" slot="start"></ion-icon>
              <ion-input [label]="'amount'|translate" labelPlacement="stacked" type="number" [step]="1" [min]="1"
                [max]="60" [(ngModel)]="view.aiOptions.amount" (ionInput)="amountChanged()"></ion-input>
            </ion-item>

            <!-- Image aspect ratio (expert mode) -->
            <!--
            <ion-item lines="none"
              [hidden]="!view.expertMode || (!view.selectedTypesList || !view.selectedTypesList.image)">
              <ion-icon [hidden]="!view.isDesktop" name="resize-outline" slot="start"></ion-icon>
              <ion-select [label]="'image_aspect_ratio' | translate" labelPlacement="stacked"
                (ionChange)="aspectRatioChanged()" [(ngModel)]="view.aspect_ratio_images" [okText]="'okay' | translate"
                [cancelText]="'cancel' | translate">
                <ion-select-option *ngFor="let aspect_ratio of view.aspect_ratios"
                  [innerHTML]="aspect_ratio.name | translate" [value]="aspect_ratio.uid"></ion-select-option>
              </ion-select>
            </ion-item>
            -->

            <!-- Video aspect ratio (expert mode) -->
            <ion-item lines="none"
              [hidden]="!view.expertMode || (!view.selectedTypesList || !view.selectedTypesList.video)">
              <ion-icon [hidden]="!view.isDesktop" name="resize-outline" slot="start"></ion-icon>
              <ion-select interface="popover" [label]="'video_aspect_ratio' | translate" labelPlacement="stacked"
                (ionChange)="aspectRatioChanged()" [(ngModel)]="view.aspect_ratio_videos" [okText]="'okay' | translate"
                [cancelText]="'cancel' | translate">
                <ion-select-option *ngFor="let aspect_ratio of view.aspect_ratios"
                  [innerHTML]="aspect_ratio.name | translate" [value]="aspect_ratio.uid"></ion-select-option>
              </ion-select>
            </ion-item>

            <!--
            <ion-item lines="none" [hidden]="!!view.expertMode || (!view.selectedTypesList || !view.selectedTypesList.video)">
              <ion-icon [hidden]="!view.isDesktop" name="tv-outline" slot="start"></ion-icon>
              <ion-select [label]="'video_resolution' | translate" labelPlacement="stacked" (ionChange)="resolutionChanged()" [(ngModel)]="view.resolution" [okText]="'okay' | translate" [cancelText]="'cancel' | translate">
                <ion-select-option *ngFor="let resolution of view.resolutions" [innerHTML]="resolution.name | translate" [value]="resolution.uid"></ion-select-option>
              </ion-select>
            </ion-item>
            -->

            <!-- Quality (not Video Quality anymore)-->
            <ion-item lines="none" [hidden]="!view.expertMode">
              <ion-icon [hidden]="!view.isDesktop" name="eye-outline" slot="start"></ion-icon>
              <ion-select interface="popover" [label]="'video_quality' | translate" labelPlacement="stacked" (ionChange)="qualityChanged()"
                [(ngModel)]="view.quality" [okText]="'okay' | translate" [cancelText]="'cancel' | translate">
                <ion-select-option *ngFor="let quality of view.qualities" [innerHTML]="quality.name | translate"
                  [value]="quality.uid"></ion-select-option>
              </ion-select>
            </ion-item>
            
            <ion-item lines="none"
              [hidden]="!view.expertMode || (!view.selectedTypesList || !view.selectedTypesList.video)">
              <ion-icon [hidden]="!view.isDesktop" name="recording-outline" slot="start"></ion-icon>
              <ion-input [label]="'fps'|translate" labelPlacement="stacked" type="number" [step]="1" [min]="1"
                [max]="60" [(ngModel)]="view.aiOptions.fps"></ion-input>
            </ion-item>

            <ion-grid>
              <ion-row>
                
                <ion-col>
                  <ion-item lines="none" [hidden]="!view.expertMode">
                    <ion-icon [hidden]="!view.isDesktop" name="swap-vertical-outline" slot="start"></ion-icon>
                    <ion-input [label]="'height'|translate" labelPlacement="stacked" type="number" [step]="1" [min]="128"
                      [max]="2048" [(ngModel)]="view.aiOptions.height"></ion-input>
                  </ion-item>
                </ion-col>

                <ion-col>
                  <ion-item lines="none" [hidden]="!view.expertMode">
                    <ion-icon [hidden]="!view.isDesktop" name="swap-horizontal-outline" slot="start"></ion-icon>
                    <ion-input [label]="'width'|translate" labelPlacement="stacked" type="number" [step]="1" [min]="128"
                      [max]="2048" [(ngModel)]="view.aiOptions.width"></ion-input>
                  </ion-item>
                </ion-col>

              </ion-row>
            </ion-grid>

          </div>

        </ion-card>
      </div>

      <!-- Templates (If text overlay is set)-->
      <div class="col-inner" [hidden]="!view.expertMode || !view.selectedInputModesList || !view.media.title"
        *ngIf="!!view.project && !!view.project.config && !!view.templates && !!view.templates.length">
        <div class="card ion-card">

          <ion-card-header>

            <ion-card-title (click)="toggleCard('templates')">
              <span [innerHTML]="'templates'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('templates')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.templates.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <ion-searchbar [(ngModel)]="view.templateSearch" [hidden]="!cards.templates.open"
            [placeholder]="'search_media_templates'|translate" (ionInput)="searchTemplates()"></ion-searchbar>

          <ion-grid class="templates-grid" [hidden]="!cards.templates.open">
            <ion-row>
              <ion-col size="6" *ngFor="let template of view.templates; let i = index; trackBy:trackItems">

                <ion-card>

                  <ion-thumbnail>
                    <ion-img [src]="template.photo" (ionError)="thumbnailLoadingFailed(template)"></ion-img>
                  </ion-thumbnail>

                  <ion-item lines="none">
                    <ion-checkbox [(ngModel)]="template.checked" (ionChange)="onTemplateCheckboxClicked(template, i)"
                      justify="start" labelPlacement="end" [innerHTML]="template.name"
                      class="ion-text-wrap"></ion-checkbox>
                  </ion-item>

                </ion-card>

              </ion-col>
            </ion-row>
          </ion-grid>

        </div>
      </div>

      <!-- Look and Feel (If text overlay is set) -->
      <div [class.success]="!!view.media.logo_src && !!view.media.logo_bg_color"
        *ngIf="!!view.project && !!view.project.config" class="col-inner"
        [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList || !view.media.title">
        <div class="card ion-card">

          <ion-card-header>

            <ion-card-title (click)="toggleCard('lookAndFeel')">
              <span [innerHTML]="'look_and_feel'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('lookAndFeel')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.lookAndFeel.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <ion-card-content [hidden]="!cards.lookAndFeel.open">
            <p [innerHTML]="'media_creator_step_1_header_text'|translate"></p>
          </ion-card-content>

          <ion-radio-group [hidden]="!cards.lookAndFeel.open" [(ngModel)]="view.selectType">

            <ion-item [disabled]="!view.project || !view.project.uid">
              <ion-radio justify="start" labelPlacement="end" value="project" [disabled]="!view.project || !view.project.uid" class="ion-text-wrap">
                <ion-label [innerHTML]="'media_creator_type_project_label'|translate"></ion-label>
              </ion-radio>
            </ion-item>

            <ion-item lines="none">
              <ion-radio justify="start" labelPlacement="end" value="custom" class="ion-text-wrap">
                <ion-label [innerHTML]="'media_creator_type_custom_label'|translate"></ion-label>
              </ion-radio>
            </ion-item>

          </ion-radio-group>

        </div>

        <pipeline-template-settings *ngIf="!!view.project && !!view.project.config"
          [hidden]="view.selectType != 'custom'" [(template)]="view.project.config"
          [(view)]="view"></pipeline-template-settings>

      </div>

      <!-- Multilingual -->
      <div [class.success]="!!view.output_languages" class="col-inner"
        [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList || !view.media.title">
        <ion-card>

          <ion-card-header>

            <ion-card-title (click)="toggleCard('multilingual')">
              <span [innerHTML]="'multilingual'|translate"></span>
            </ion-card-title>

            <ion-button class="card-toggle" (click)="toggleCard('multilingual')" icon-only color="dark" fill="clear">
              <ion-icon [name]="cards.multilingual.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
            </ion-button>

          </ion-card-header>

          <ion-card-content [hidden]="!cards.multilingual.open">
            <p [innerHTML]="'media_creator_multilingual_text'|translate"></p>
          </ion-card-content>

          <pipeline-languages-select [hidden]="!cards.multilingual.open" icon="language-outline"
            [label]="'languages'|translate" [(key)]="view.output_languages"
            (changed)="onLanguageChange($event)"></pipeline-languages-select>

        </ion-card>
      </div>

      <!-- Voiceover -->
      <div class="col-inner"
        [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList || !view.selectedTypesList.video || !view.media.title">
        <pipeline-voiceover-card [options]="{ input: (view.audioInputText || '')}"
          (settingsChanged)="onVoiceoverSettingsChanged($event)"></pipeline-voiceover-card>
      </div>

      <!-- Music -->
      <div class="col-inner"
        [hidden]="!view.selectedTypesList || !view.selectedInputModesList || !view.selectedTypesList.video">
        <pipeline-music-card [options]="{ input: (view.audioInputText || '')}"
          (settingsChanged)="onMusicSettingsChanged($event)"></pipeline-music-card>
      </div>

      <!-- Visual speaker -->
      <div class="col-inner"
        [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList || !view.selectedTypesList.video || !view.media.title">
        <pipeline-visual-speakers-card
          (settingsChanged)="onVisualSpeakersSettingsChanged($event)"></pipeline-visual-speakers-card>
      </div>

      <!-- AI Settings -->
      <ion-card [hidden]="!view.hasSelectedTypes || !view.selectedInputModesList || !view.expertMode">

        <ion-card-header>

          <ion-card-title (click)="toggleCard('ai')">
            <span [innerHTML]="'ai_settings'|translate"></span>
          </ion-card-title>

          <ion-button class="card-toggle" (click)="toggleCard('ai')" icon-only color="dark" fill="clear">
            <ion-icon [name]="cards.ai.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
          </ion-button>

        </ion-card-header>

        <ion-list [hidden]="!cards.ai.open">

          <ion-item lines="none" (click)="chooseModel()">
            <ion-icon name="hardware-chip-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'model'|translate"></ion-label>
            <ion-note [innerHTML]="(view.aiOptions.model || 'select')|translate" slot="end"></ion-note>
          </ion-item>

          <ion-item lines="none">
            <ion-icon name="repeat-outline" slot="start"></ion-icon>
            <ion-input [label]="'sampling_steps'|translate" type="number" [step]="1" [min]="1" [max]="150"
              [(ngModel)]="view.aiOptions.steps"></ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-icon name="thermometer-outline" slot="start"></ion-icon>
            <ion-input [label]="'cfg_scale'|translate" type="number" [step]="1" [min]="1" [max]="30"
              [(ngModel)]="view.aiOptions.cfg_scale"></ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-icon name="eye-outline" slot="start"></ion-icon>
            <ion-checkbox [(ngModel)]="view.aiOptions.restore_faces" justify="start" labelPlacement="end"
              [innerHTML]="'restore_faces'|translate"></ion-checkbox>
          </ion-item>

        </ion-list>

      </ion-card>

    </ion-col>

    <!-- preview -->
    <ion-col #mediaCreatorContent [size]="view.isDesktop ? 9 : 12">

      <pipeline-intro-card *ngIf="!!view.isDesktop && !!view.mediaCreatorIntroCard" class="introCard"
        [hidden]="!!view.mediaCreatorIntroCard.hidden" [(view)]="view.mediaCreatorIntroCard"></pipeline-intro-card>

      <pipeline-spline-viewer *ngIf="!!view.loadingOptions && !!view.showSplineView"
        [options]="view.loadingOptions"></pipeline-spline-viewer>

      <!-- step 2: pick -->
      <div class="step-wrapper" *ngIf="(view.phase === 'pick') || (view.phase === 'preview')"
        [hidden]="!view.hasSelectedTypes || !!view.loading">
        <pipeline-media-items [view]="view"
          (selectedItemsChanged)="selectedMediaItemsChanged($event)"></pipeline-media-items>
      </div>

      <!-- step 3: queue -->
      <div class="step-wrapper" *ngIf="view.phase === 'queue'" [hidden]="!view.hasSelectedTypes || !!view.loading">

        <pipeline-spline-viewer [options]="view.loadingOptions"
          [hidden]="!view.loading || (view.queue && view.queue.length)"></pipeline-spline-viewer>

        <h2 [innerHTML]="('create_media_phase_queue_step_' + (view.queueTimeIndex))|translate"></h2>

        <pipeline-media-queue [(view)]="view"></pipeline-media-queue>

        <!--
        <getgenius-queue-footer></getgenius-queue-footer>
        -->

      </div>

      <!-- images picker view (assets & extend)-->
      <ion-grid class="ai-images" *ngIf="((view.phase === 'extend') || (view.phase === 'pick')) && !!view.images"
        [hidden]="!view.hasSelectedTypes">

        <ion-row>
          <ion-col *ngFor="let image of view.images; let i = index;" [size]="view.colSize">
            <ion-card class="mediaItem" [class.active]="!!view.selectedStrings && !!view.selectedStrings[image]">

              <ion-toolbar class="ion-no-border" *ngIf="!!image">
                <ion-buttons slot="start">
                  <ion-item lines="none">

                    <ion-checkbox [(ngModel)]="image.checked" (ionChange)="onItemCheckboxClicked(image, i)"
                      [disabled]="image.disabled || !image.url" slot="start"></ion-checkbox>

                    <ion-button (click)="saveImage(image)" [disabled]="image.disabled || !image.url" fill="clear"
                      icon-only color="dark" slot="end">
                      <ion-icon name="cloud-download-outline"
                        [color]="image.downloaded ? 'success' : 'dark'"></ion-icon>
                    </ion-button>

                    <!--
                <ion-button
                  (click)="editImage(image)"
                  [disabled]="image.disabled || !image.url"
                  fill="clear"
                  icon-only
                  color="dark"
                  slot="end"
                >
                  <ion-icon name="create-outline"></ion-icon>
                </ion-button>
                -->

                    <ion-button [hidden]="image.loading" [disabled]="image.disabled || !image.url" icon-only
                      color="dark" size="small" fill="clear" slot="end" (click)="openImage(image)">
                      <ion-icon name="open-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                </ion-buttons>

                <ion-buttons slot="end">
                  <pipeline-rate-item-buttons [(item)]="view.images[i]"></pipeline-rate-item-buttons>
                </ion-buttons>
              </ion-toolbar>

              <ion-img *ngIf="!!image && (image.type !== 'video' && !!image.url)"
                (click)="chooseMediaFromList(image, i, view.images)" [src]="image.photo || image.url"></ion-img>

              <video *ngIf="!!image && (image.type === 'video' && !!image.url)"
                (click)="chooseMediaFromList(image, i, view.images)" [src]="image.url" loop autoplay webkit-playsinline
                playsinline muted></video>

              <ion-skeleton-text [animated]="!!view.blLoadSimultaneously || view.currentIndex === i" class="image"
                [hidden]="!!image && !!image.url"></ion-skeleton-text>

              <ion-spinner [hidden]="
                  (!!image && !!image.url) ||
                  (!view.blLoadSimultaneously && view.currentIndex !== i)
                "></ion-spinner>

              <ion-icon name="hourglass-outline" class="video" [hidden]="
                  (!!image && !!image.url) ||
                  !!view.blLoadSimultaneously ||
                  view.currentIndex === i
                "></ion-icon>

              <ion-icon name="checkmark-outline" class="video" [hidden]="!view.selectedStrings[image]"></ion-icon>

              <!--<p class="estimated-time" *ngIf="!!image.estimated_time" [innerHTML]="image.estimated_time + ' ' + ('seconds'|translate)"></p>-->

              <ion-item lines="none" *ngIf="!!image">

                <ion-label [innerHTML]="image.search_query" class="ion-text-wrap"></ion-label>

                <ion-skeleton-text [animated]="!!view.blLoadSimultaneously || view.currentIndex === i"
                  style="float: left; width: 35%; margin-right: 10px" [hidden]="!!image.url"></ion-skeleton-text>
                <ion-skeleton-text [animated]="!!view.blLoadSimultaneously || view.currentIndex === i"
                  style="float: left; width: 55%; margin-right: 10px" [hidden]="!!image.url"></ion-skeleton-text>

                <ion-skeleton-text [animated]="!!view.blLoadSimultaneously || view.currentIndex === i"
                  style="float: left; width: 75%; margin-right: 10px" [hidden]="!!image.url"></ion-skeleton-text>
                <ion-skeleton-text [animated]="!!view.blLoadSimultaneously || view.currentIndex === i"
                  style="float: left; width: 5%; margin-right: 10px" [hidden]="!!image.url"></ion-skeleton-text>

                <ion-skeleton-text [animated]="!!view.blLoadSimultaneously || view.currentIndex === i"
                  style="float: left; width: 15%; margin-right: 10px" [hidden]="!!image.url"></ion-skeleton-text>
                <ion-skeleton-text [animated]="!!view.blLoadSimultaneously || view.currentIndex === i"
                  style="float: left; width: 60%; margin-right: 10px" [hidden]="!!image.url"></ion-skeleton-text>

                <ion-button [hidden]="!image.url" slot="end" fill="clear" color="dark" icon-only
                  (click)="itemInfo(image, $event)">
                  <ion-icon name="information-circle-outline"></ion-icon>
                </ion-button>

              </ion-item>

            </ion-card>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-col>

  </ion-row>

</ion-grid>