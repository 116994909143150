import { Injectable } from '@angular/core';

import EditorJS from '@editorjs/editorjs';

//import AIText from '@alkhipce/editorjs-aitext';
import CodeTool from '@editorjs/code';
import Embed from '@editorjs/embed'; 
import Header from '@editorjs/header'; 
//import ImageTool from '@editorjs/image';
import InlineCode from '@editorjs/inline-code'; 
import LinkTool from '@editorjs/link';
import List from '@editorjs/list'; 
import Marker from '@editorjs/marker'; 
import Paragraph from '@editorjs/paragraph'; 
import Quote from '@editorjs/quote'; 
import RawTool from '@editorjs/raw';
import SimpleImage from "@editorjs/simple-image";
import Table from '@editorjs/table'; 
import Underline from '@editorjs/underline'; 

import { AppcmsService } from 'src/app/services/core/appcms.service';
//import { MediaextendService } from 'src/app/services/media/mediaextend.service';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  constructor(
    private AppCMS: AppcmsService,
    //private media: MediaextendService,
  ) {

  }

  async create(element: any = null, options: any = {}) {

    const editor = new EditorJS(Object.assign(options, {
      autofocus: false,
      holder: element,
      placeholder: 'Your content goes here',
      //readOnly: true,
      tools: {
        code: CodeTool,
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              coub: true
            }
          }
        },
        header: {
          class: Header,
          config: {
            placeholder: 'Enter a header',
            levels: [2, 3, 4, 5],
            defaultLevel: 3
          },
          inlineToolbar : true
        }, 
        image: SimpleImage,
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: this.AppCMS.getRequestUrl('editor', ['import', 'link', 'url']),
          }
        },
        list: List,
        Marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M',
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        quote: Quote,
        raw: RawTool,
        table: Table,
        underline: Underline
      }, 
    }));

    return editor;
  }

}
