import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { PaymentsService } from 'src/app/services/payments/payments.service';
import { EventsService } from "src/app/services/core/events.service";
import { UserService } from 'src/app/services/core/user.service';
import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { CheckoutService } from 'src/app/services/ecommerce/checkout.service';
import { OrdersService } from 'src/app/services/ecommerce/orders.service';
import { ModalService } from "src/app/services/core/modal.service";
import { StripeService } from '../../../services/payments/stripe.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.page.html',
  styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnInit {
  @ViewChild('stripeEmbed', { read: ElementRef }) private stripeEmbed: ElementRef;

  _basket: basketInfo;

  applePayAvailable: boolean = false;

  deliveryOptions: any;

  googlePayAvailable: boolean = false;

  isOpen: boolean;

  paymentOptions: any;

  state: state = {};

  tmpOrder: any;

  user: user;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    title: 'payment',
  };

  constructor(
    private basket: BasketService,
    private checkoutService: CheckoutService,
    private events: EventsService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private ordersService: OrdersService,
    private payments: PaymentsService,
    private stripe: StripeService,
    private userService: UserService,
    private viewService: ViewService,
  ) {

  }

  calcViewVars() {
    this.deliveryOptions = this.checkoutService.getDeliveryOptions() || {};
    this.deliveryOptions.repeat = this.deliveryOptions.repeat || false;

    this.tmpOrder = this.ordersService.getTmpOrder() || this.ordersService.createTmpOrder();
    this.paymentOptions = this.payments.getPaymentOptions();
    this.user = this.userService.getUser() || {};

    this.view = this.viewService.calcVars(this.view);
  }

  async checkCanMakeApplePayments() {
    /*
    await this.applePay.canMakePayments()
    .then(blCanMakePayments => {
      this.applePayAvailable = !!blCanMakePayments;
    })
    .catch(() => {
      this.applePayAvailable = false;
    });
    */
  }

  async dismiss(data: any = null, role: string | null = 'dismiss') {
    let modalService = (await this.view.modalService) || this.modalService;
    modalService.dismiss();
  }

  dateChanged() {
  }

  initEvents() {

    this.events.subscribe('payments:stripe:init', () => {
      console.log('payment: requested stripe init');
      this.initStripe();
    });

    this.events.subscribe('payments:paypal:init', () => {
      console.log('payment: requested paypal init');
      this.renderPayPalButton();
    });

  }

  initStripe() {

    if(!this.stripeEmbed || !this.stripeEmbed.nativeElement) {
      console.warn('missing stripe wrapper element');
      return false;
    }

    this.view.stripeCheckout = this.stripe.initWebEmbed(this.stripeEmbed.nativeElement);
    console.log('this.view.stripeCheckout', this.view.stripeCheckout);
    
    this.view.hasStripeUI = true;
  }

  async ionViewWillEnter() {
    this._basket = await this.basket.calculateBasketInfo();
    this.tmpOrder = this.ordersService.getTmpOrder();
    this.user = this.userService.getUser() || {};
  }

  login() {
    this.userService.setAfterLoginRedirectUrl('/payment');
    this.userService.setAfterRegisterRedirectUrl('/payment');
    this.navCtrl.navigateForward('/login');
  }

  next() {
    this.dismiss(null, 'done');
    this.events.publish('view:checkout');
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    try {
      this.checkCanMakeApplePayments();
    } catch (e) {
      console.warn('e apply pay', e);
    }
  }

  renderPayPalButton() {
    try {
      let _this = this;
      setTimeout(() => {
        // Render the PayPal button into #paypal-button-container
        <any>window['paypal'].Buttons({

          // Set up the transaction
          createOrder: (data, actions) => {
            console.log('_this._basket', _this._basket);
            let createParams = {
              purchase_units: [{
                amount: {
                  value: _this._basket.price_a
                }
              }]
            };
            return actions.order.create(createParams);
          },

          // Finalize the transaction
          onApprove: (data, actions) => {
            return actions.order.capture()
              .then(function (details) {
                // Show a success message to the buyer
                alert('Transaction completed by ' + details.payer.name.given_name + '!');
              })
              .catch(err => {
                console.error(err);
              })
          }
        }).render('#paypal-button-container');
      }, 500)
    } catch (e) {
      console.warn('rendering paypal buttons failed', e);
    }
  }

  use(paymentMethod: string) {
    this.payments.setPaymentMethod(paymentMethod);
    this.paymentOptions = this.payments.getPaymentOptions();
  }

}