<ion-header class="ion-no-border">
  
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <ion-button (click)="dismiss()" [hidden]="view.isModal === false">
          <ion-icon name="close"></ion-icon>
      </ion-button>
      
    </ion-buttons>
  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <ion-card>
      <ion-radio-group [(ngModel)]="view.orderBy" (ionChange)="onChanged()">

        <ion-item lines="none" *ngFor="let option of view.options" [disabled]="option.disabled">
          <ion-radio [value]="option.indent"></ion-radio>
          <ion-label [innerHTML]="'order_by_' + option.indent|translate"></ion-label>
        </ion-item>
    
      </ion-radio-group>
    </ion-card>
  
  </div>
  
</ion-content>
