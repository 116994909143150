<ng-lottie *ngIf="!!options.path"
  width="100%"
  height="100%"
  containerClass="lottie-loading-stage-wrapper"
  [options]="options"
  (animationCreated)="animationCreated($event)"
  (configReady)="configReady()"
  (dataReady)="dataReady()"
  (domLoaded)="domLoaded()"
  (destroy)="destroy($event)"
  (error)="error($event)"
></ng-lottie>