import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'app-ai-task-execution-settings',
  templateUrl: './ai-task-execution-settings.page.html',
  styleUrls: ['./ai-task-execution-settings.page.scss'],
})
export class AiTaskExecutionSettingsPage implements OnInit {

  aiWorker: any;

  state: state = {};
  
  task: aiTask;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_task_execution_settings_top_card',
      subtitle: 'ai_task_execution_settings_top_card_subtitle',
      text: 'ai_task_execution_settings_top_card_text',
      title: 'ai_task_execution_settings_top_card_title',
    },
    title: 'ai_task_execution_settings',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private navParams: NavParams,
    private toolsService: ToolsService,
  ) {
    this.aiWorker = this.navParams.get('aiWorker');
    this.task = this.navParams.get('task');
  }

  calcViewVars() {
    this.view.isDesktop = this.toolsService.isDesktop();
    this.view.isModal = this.modalService.isModal();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  dismiss(data: any = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  run() {
    this.view.loading = true;

    this.aiWorker.setCurrentTask(this.task);

    this.aiWorker.executeAITaskSet()
    .catch((error: any) => {
      console.warn('> run set error', error);

      this.view.running = false;
      this.events.publish('error', error);
    });
  }

}
