import { Injectable } from '@angular/core';

import {
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
  ) {

  }

  async askTrackingPermission() {
    try {
      const response = await AppTrackingTransparency.requestPermission();

      //console.log(response);
      // { status: 'authorized' } for example

      return response;
    } catch(e) {
      console.warn('request tracking permission error (1)', e);
    }
  }
  
  async readTrackingPermission() {
    const response = await AppTrackingTransparency.getStatus();

    //console.log(response);
    // { status: 'authorized' } for example

    return response;
  }

}