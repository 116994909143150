import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ConfigService } from 'src/app/services/core/config.service';
//import { ModalService } from 'src/app/services/core/modal.service';
import { PurchaseService } from "src/app/services/ecommerce/purchase.service";
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

//import { AbonnementsPage } from '../../pages/abonnements/abonnements/abonnements.page';

@Injectable({
  providedIn: 'root'
})
export class AbonnementService {

  appConfig: pipelineAppConfig;

  constructor(
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private configService: ConfigService,
    private loading: LoadingController,
    //private modalService: ModalService,
    private purchaseService: PurchaseService,
    private translations: TranslationService,
    private user: UserService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  delete(abonnement: any) {
    return new Promise((resolve, reject) => {
      console.log('> delete', abonnement);
    });
  }

  calcVip(post: post) {
    
    let blIsAdmin = (
      this.user.isType('Admin') ||
      this.user.isType('Creator') ||
      this.user.isType('Moderator')
    );

    if(blIsAdmin || post.sponsored || (post.type !== 'post')) {
      return false;
    }

    if(post.vip) {
      return true;
    }

    // @debug
    return Math.random() < 0.05;

    /*
    let bl: boolean = false,
        rating = post.rating && post.rating.count ? post.rating.count : 1;

    console.log('> calcVip rating', rating, post.name, post.rating);

    return bl;
    */
  }
  
  getActiveExtensions(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['abonnement', 'extensions'], {}, blForceRefresh);
  }

  getCurrentVersion() {
    const user = this.user.getUser() || {};

    if(this.user.isType('Admin')) {
      return 'premium';
    }

    return (user && user.classifications && user.classifications.abo ? user.classifications.abo : 'free');
  }

  getSubscribedExtensions(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      const loading: any = await this.loading.create();
      loading.present();
      
      this.AppCMS.loadPluginData('pipeline', options, ['abonnement', 'extensions', 'subscribed'], {}, blForceRefresh)
      .then((response: any) => {
        loading.dismiss();
        resolve(response);
      })
      .catch((error: any) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  async hasMatchingAbonnement(requiredAbonnement: string|string[]) {
    let blHasMatching: boolean = false, blMatchingSubscribed: boolean  = false;

    try {
      let extensions: any = await this.getActiveExtensions();

      if(typeof requiredAbonnement === 'string') {
        requiredAbonnement = [requiredAbonnement];
      }
      
      if(extensions && extensions.length) {
        extensions.forEach((extension: any) => {
          let blIsExtensionMatching: boolean = (requiredAbonnement.indexOf(extension.indent) !== -1);
          blHasMatching = blHasMatching || blIsExtensionMatching;

          if(!!blIsExtensionMatching) {
            blMatchingSubscribed = !!(blHasMatching && !!extension.subscribed);
          }
        });
      }

    } catch(e) {
      console.warn('> loading abo extensions failed', e);
    }

    if(!blHasMatching) {
      return true;
    } else
    if(!!blHasMatching && !blMatchingSubscribed) {
      console.warn('> not subscribed to abonnement', requiredAbonnement);
    } else
    if(!!blHasMatching && !!blMatchingSubscribed) {
      return true;
    }

    return false;
  }

  onAcceptBeta() {
    return new Promise((resolve, reject) => {
      this.user.setClassifications({
        abo: 'premium',
        isBetaTester: true,
      })
      .then(() => {
        this.translations.get([
          'prompt_beta_test_submitted_title',
          'prompt_beta_test_submitted_message',
          'okay',
        ])
        .subscribe(async (translations: any) => {
          
          const alert: any = await this.alertCtrl.create({
            header: translations.prompt_beta_test_submitted_title,
            message: translations.prompt_beta_test_submitted_message,
            buttons: [
              {
                text: translations.okay,
                role: 'cancel',
              }
            ]
          });
  
          alert.present();
  
          resolve({
            abo: 'premium',
          });
        });
      })
      .catch(reject);
    });
  }

  promptBetaTest() {
    return new Promise((resolve, reject) => {
      this.translations.get([
        'prompt_beta_test_title',
        'prompt_beta_test_message',
        'accept_beta',
        'cancel',
      ])
      .subscribe(async (translations: any) => {
        const alert: any = await this.alertCtrl.create({
          header: translations.prompt_beta_test_title,
          message: translations.prompt_beta_test_message,
          buttons: [
            {
              text: translations.accept_beta,
              handler: () => {
                this.onAcceptBeta().then(resolve).catch(reject);
              },
              role: 'submit',
            },
            {
              text: translations.cancel,
              role: 'cancel',
            }
          ]
        });
        alert.present();
      });
    });
  }

  pick(options: chooseConfig = null) {
    return new Promise(async (resolve, reject) => {

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: await this.getActiveExtensions(),
        labelKey: 'name',
        service: this,
        subLabelKey: 'description',
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  /*
  showAboPrompt(aboExtensions: any[]) {
    return new Promise(async (resolve, reject) => {
      
      let aboModal = await this.modalService.create({
        component: AbonnementsPage,
        componentProps: {
          service: this,
          extensions: aboExtensions,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal'
      });
      
      aboModal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      this.modalService.present(aboModal);
    });
  }
  */

  switch(version: any) {
    return new Promise(async (resolve, reject) => {
      //const user = this.user.getUser() || {};

      /*
      Old, store purchased licence and create user after purchasing instead
      if(!user || !user.uid) {
        reject('error_missing_user_uid');
        return;
      }
      */

      if(version !== 'free') {
        this.purchaseService.purchase(version).then(resolve).catch(reject);
      } else {
        this.updateUserAfterSwitch(version).then(resolve).catch(reject);
      }
    });
  }

  updateUserAbonnement(userId: number, aboId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      relation: {
        abonnement_uid: aboId,
        user_uid: userId,
      }
    }, ['abonnement', 'extensions', 'update']);
  }

  updateUserAfterSwitch(versionName: string, user: user|null = null, blSetUser: boolean = true) {
    return new Promise(async (resolve, reject) => {
      user = (!!user ? user : this.user.getUser() || {});

      if(!user || !user.uid) {
        reject('error_missing_user_uid');
        return;
      } else {
        user.classifications = user.classifications || {};
        user.classifications.abo = versionName;
  
        if(!!blSetUser) {
  
          const loading: any = await this.loading.create({
            spinner: 'circular',
          });
          
          loading.present();
          
          this.user.setUser(user, true)
          .then((user: user) => {
            loading.dismiss();
            resolve(user);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
          
        } else {
          this.user.update(user).then(resolve).catch(reject);
        }
      }
    });
  }

}
