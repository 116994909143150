<ion-modal #modifiedGmtModal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime [(ngModel)]="view.filterDate" id="filterDatePicker" #filterDatePicker (ionChange)="dateChanged($event)" (ionInput)="dateChanged($event)"></ion-datetime>
  </ng-template>
</ion-modal>

<ion-action-sheet trigger="selectionOptionsActionSheet" [header]="'action'|translate" [buttons]="actionSheetButtons" (didDismiss)="onSelectionOptionsActionSheetDismiss($event)"></ion-action-sheet>

<ion-toolbar class="container second-toolbar" *ngIf="(!!view.multiple && (!!items && !!items.length)) || !!view.showLanguagesSelect">

  <ion-grid>
    <ion-row>

      <!-- select all -->
      <ion-col *ngIf="!view.hideSelectAll" [size]="view.mode === 'widget' ? (view.isDesktop ? 9 : 6) : (view.isLargeScreen ? 2 : 3)">
        <ion-button (click)="selectAll()" class="select-all-btn" color="dark" fill="clear" [disabled]="view.loading">

          <ion-icon name="checkbox-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <p [innerHTML]="(!!view.allSelected ? 'unselect_all' : 'select_all')|translate"></p>
            <small>
              <span>
                <span [innerHTML]="!!view.selectedItems && !!view.selectedItems.length ? (view.selectedItems.length || 0) : 0"></span>
                /
              </span>
              <span [innerHTML]="(!!items && !!items.length ? items.length : 0)"></span>&nbsp;<span [innerHTML]="'items'|translate"></span>
            </small>
          </ion-label>
    
        </ion-button>
      </ion-col>
      
      <!-- selection actions -->
      <ion-col *ngIf="(view.mode !== 'pick') && (!!options && !!options.length)" [size]="view.isDesktop ? view.isLargeScreen ? 2 : 3 : 1">

        <!-- Desktop selection action picker -->
        <ion-item lines="none" *ngIf="!!view.isDesktop">
          <ion-select interface="popover" [(ngModel)]="view.selectionAction" [label]="'selection'|translate" labelPlacement="stacked" [placeholder]="'action'|translate"
            (ionChange)="_onSelectionActionChanged()" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
            [disabled]="!options || !options.length || view.loading || !view.hasSelectedItems || !(!!items && !!items.length)">
            <ion-select-option *ngFor="let option of options" [value]="option.value || option.uid"
              [innerHTML]="(option.label || option.name)|translate"></ion-select-option>
          </ion-select>
        </ion-item>

        <!-- Mobile selection action picker -->
        <ion-button id="selectionOptionsActionSheet" *ngIf="!view.isDesktop" icon-only fill="clear" color="dark" [disabled]="!options || !options.length || view.loading || !view.hasSelectedItems || !(!!items && !!items.length)">
          <ion-icon name="chevron-down-outline" slot="start"></ion-icon>
        </ion-button>

      </ion-col>

      <!-- language picker -->
      <ion-col *ngIf="(view.mode !== 'pick') && !!view.showLanguagesSelect" [size]="view.isDesktop ? view.isLargeScreen ? 4 : 6 : 4">
        <pipeline-languages-select [(key)]="view.language" (changed)="_onLanguageChanged($event)" [options]="view.languagesOptions"></pipeline-languages-select>
      </ion-col>

      <!-- view modes -->
      <ion-col *ngIf="(view.mode !== 'pick') && !!view.showViewModeSelect" [size]="view.isDesktop ? view.isLargeScreen ? 2 : 3 : 4">
        <div class="view-modes-wrapper">
    
          <ion-button size="small" icon-only [hidden]="!view.expertMode" [disabled]="view.loading || !(items && items.length)" (click)="setView('chart')" fill="clear" color="dark">
            <ion-icon [name]="view.viewType === 'chart' ? 'pie-chart' : 'pie-chart-outline'"></ion-icon>
          </ion-button>

          <ion-button size="small" icon-only [hidden]="!view.expertMode" [disabled]="view.loading || !(items && items.length)" (click)="setView('calendar')" fill="clear" color="dark">
            <ion-icon [name]="view.viewType === 'calendar' ? 'calendar' : 'calendar-outline'"></ion-icon>
          </ion-button>
                      
          <ion-button size="small" icon-only [disabled]="view.loading || !(items && items.length)" (click)="setView('grid')" fill="clear" color="dark">
            <ion-icon [name]="view.viewType === 'grid' ? 'grid' : 'grid-outline'"></ion-icon>
          </ion-button>

          <ion-button size="small" icon-only [disabled]="view.loading || !(items && items.length)" (click)="setView('list')" fill="clear" color="dark">
            <ion-icon [name]="view.viewType === 'list' ? 'list' : 'list-outline'"></ion-icon>
          </ion-button>

          <!-- filters toggle button (mobile) -->
          <ion-button *ngIf="!view.isDesktop && ((!!filters && !!filters.length) || (view.mode !== 'pick' && !!view.showViewModeSelect))" class="filter-btn" shape="round" size="small" icon-only [fill]="view.showFilters ? 'solid' : 'clear'" color="primary" (click)="toggleFilters()">
            <ion-icon [name]="view.showFilters ? 'filter' : 'filter-outline'"></ion-icon>
          </ion-button>
          
        </div>

      </ion-col>

    </ion-row>

    <!-- filters-->
    <ion-row *ngIf="!!filters && !!filters.length" [hidden]="!view.isDesktop && !view.showFilters" class="filters-wrapper">

      <ion-col *ngFor="let filter of filters" [size]="view.isDesktop ? 3 : 6">

        <ion-item lines="none" *ngIf="filter.type === 'checkbox' || filter.type === 'date' || filter.type === 'select'" class="ion-text-wrap">

          <!-- if icon is set -->
          <ion-icon *ngIf="!!filter.icon && (filter.type !== 'date')" [name]="filter.icon" slot="start"></ion-icon>

          <!-- checkbox -->
          <ion-checkbox *ngIf="filter.type === 'checkbox'" [(ngModel)]="filter.value" [innerHTML]="filter.label|translate" (ionChange)="_onFilterClick(filter, $event)"></ion-checkbox>

          <!-- date / dange range -->
          <ion-icon *ngIf="filter.type === 'date'" (click)="_onFilterClick(filter, $event)" [name]="filter.icon || 'calendar-outline'" slot="start"></ion-icon>
          <ion-label *ngIf="filter.type === 'date'" [innerHTML]="(filter.value || filter.label)|translate" (click)="_onFilterClick(filter, $event)"></ion-label>

          <!-- select -->
          <ion-select interface="popover" *ngIf="filter.type === 'select' && !!filter.values" [(ngModel)]="filter.value" [label]="filter.label|translate" labelPlacement="floating" (ionChange)="_onFilterChange(filter, $event)" [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
            <ion-select-option *ngFor="let option of filter.values" [value]="option.uid" [innerHTML]="option.label|translate"></ion-select-option>
          </ion-select>

        </ion-item>

        <ion-button *ngIf="filter.type === 'button'" size="small" (click)="_onFilterClick(filter, $event)" shape="round" color="dark" fill="clear">
          <ion-label [innerHTML]="filter.label|translate"></ion-label>
        </ion-button>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-toolbar>