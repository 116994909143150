import { Injectable } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private events: EventsService,
  ) {
    
  }

  async show(text: string, color: string = 'primary') {
    /*
    await Toast.show({
      text: text,
    });
    */
    this.events.publish('toast', {
      message: text,
      color: color,
    });
  }

}
