import { AfterViewInit, Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Output, EventEmitter } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from "src/app/services/ai/ai-worker.service";

import { BrowserService } from "src/app/services/utils/browser.service";
import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from "src/app/services/core/view.service";

import { MediaPickerCardComponentModule } from 'src/app/components/media/media-picker-card/media-picker-card.component.module';
import { SplineViewerComponent } from 'src/app/components/spline/spline-viewer/spline-viewer.component';

@Component({
  selector: 'pipeline-media-creator',
  templateUrl: './media-creator.component.html',
  styleUrls: ['./media-creator.component.scss']
})
export class MediaCreatorComponent implements AfterViewInit {
  @Input() search: searchOptions;
  @Input() view: any;

  @Output() onSelectionChanged = new EventEmitter();
  @Output() onViewChanged = new EventEmitter();

  // copied creatives creator events
  @Output() onMediaChanged = new EventEmitter();
  @Output() onPhaseChanged = new EventEmitter();
  @Output() selectedItemsChanged = new EventEmitter();

  @ViewChild('itemInfoPopover') itemInfoPopover: any;
  @ViewChild('mediaCreatorContent', { read: ElementRef, }) mediaCreatorContent: ElementRef;
  @ViewChild(MediaPickerCardComponentModule) mediaPicker: any;
  @ViewChild('sidebar', { read: ElementRef, }) sidebar: ElementRef;
  @ViewChild(SplineViewerComponent) splineViewer: any;

  @ViewChild('infoPopover') infoPopover: any;

  cards: any = {
    ai: {
      open: false,
    },
    information: {
      open: true,
    },
    inputMode: {
      open: true,
    },
    lookAndFeel: {
      open: false,
    },
    media: {
      open: true,
    },
    multilingual: {
      open: false,
    },
    music: {
      open: false,
    },
    overlay: {
      open: true,
    },
    output: {
      open: true,
    },
    styles: {
      open: false,
    },
    templates: {
      open: false,
    },
    types: {
      open: true,
    },
    visualSpeaker: {
      open: false,
    },
    voiceover: {
      open: false,
    },
  };

  fallbackImg: string = './assets/img/fallback.webp';

  isInfoPopoverOpen: boolean = false;

  isItemInfoPopoverOpen: boolean = false;

  stateKeys: string[] = ['failed', 'waiting', 'validating', 'starting', 'done'];

  template: mediaTemplate;

  visibleQueueItemUids: number[] = [];

  visibleQueueItemUidsIndexes: any = {};

  constructor(
    private aiTools: AiToolsService,
    private aiWorker: AiWorkerService,
    private browser: BrowserService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private sd: StablediffusionService,
    private templates: TemplatesService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.template = this.templates.getDefaultConfig() || {};
  }

  addNewQueueItemsFromResponseToIdeasList(queue) {

    if (!queue || !queue.length) {
      return false;
    }

    this.view.ideas = this.view.ideas || [];

    queue.forEach((queueItem: any) => {
      if (this.visibleQueueItemUids.indexOf(queueItem.uid) === -1) {
        this.view.ideas.push(queueItem);
        this.visibleQueueItemUids.push(queueItem.uid);
        this.visibleQueueItemUidsIndexes[queueItem.uid] = (this.visibleQueueItemUids.length - 1);

      } else
        if (!!this.visibleQueueItemUidsIndexes[queueItem.uid]) {

          if (!!this.view.ideas[this.visibleQueueItemUidsIndexes[queueItem.uid]]) {
            queueItem.checked = !!this.view.ideas[this.visibleQueueItemUidsIndexes[queueItem.uid]].checked;
          }

          this.view.ideas[this.visibleQueueItemUidsIndexes[queueItem.uid]] = queueItem;
        }
    });

    this.view.queue = (this.view.ideas || [])
      .sort((a, b) => this.stateKeys.indexOf(b.state) - this.stateKeys.indexOf(a.state))
      .sort((a, b) => {
        if (a.url && b.url) {
          return 0;
        } else if (a.url) {
          return -1;
        } else {
          return 1;
        }
      });

    console.log('this.view.queue is now', this.view.queue);
  }

  addSection() {
    this.view.sections = this.view.sections || [];

    this.view.sections.push({
      subtext: '',
      title: '',
    });
  }

  addToQueue(options: any = {}) {

    if (!this.view.selectedItems) {
      return false;
    }

    this.view.loading = true;
    this.view.lookupUids = [];
    this.view.preview_lookup_uids = [];

    new Promise((resolve, reject) => {
      let i: number = 0, lookupUids: any[] = [];

      // pick selected items and add them to render queue
      this.view.selectedItems.forEach(async (idea: any, index: number) => {

        if (!idea.template_uid) {
          if (index === (this.view.selectedItems.length - 1)) {
            resolve(lookupUids);
          }
          return false;
        }

        let blCustom: boolean = (idea.hasOwnProperty('custom') ? idea.custom : (!!idea.template ? !!idea.template.custom : true));

        let _idea: any = JSON.parse(JSON.stringify({
          custom: blCustom,
          name: idea.name,
          state: idea.state,
          template_uid: idea.template_uid,
          type: idea.type,
          value: idea.value,
        }));

        _idea.config = idea.config || {};
        _idea.config.filters = _idea.config.filters || {};
        _idea.config.filters.types = this.view.selectedTypesList;
        _idea.config.filters.quality = (options.quality || (this.view.quality || 'auto'));

        //_idea.config.aspect_ratio = this.view.filters.aspect_ratio || this.view.filters.aspect_ratios[0];
        _idea.value = JSON.stringify(_idea.config);

        try {

          let create: any = await this.media.create(_idea);
          console.log('create', create);

          i++;

          if (!!create && !!create.uid) {
            lookupUids.push(create.uid);
          }

          if (i === this.view.selectedItems.length) {
            resolve(lookupUids);
          }
        } catch (e) {
          i++;

          console.warn('> creating queue item failed', e);

          if (i === this.view.selectedItems.length) {
            resolve(lookupUids);
          }
        }
      });
    })
      .then((lookupUids: number[]) => {
        this.view.loading = false;

        if (!!lookupUids && !!lookupUids.length) {
          this.media.setQueueLookupUids(lookupUids);
          this.watchQueue();
        } else {
          this.events.publish('error_missing_lookup_uids');
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  afterCreation() {

    if (!this.view.preview_lookup_uids || !this.view.preview_lookup_uids.length) {
      return false;
    }

    this.onAIVideosQueueItemsCreated();
  }

  async aiCreate() {

    this.cards.information.open = false;
    this.cards.inputMode.open = false;
    this.cards.types.open = false;
    //this.cards.output.open = false;

    try {
      // prepare data
      this.startManually();
      this.calcCurrentTemplates();
    } catch (e) {
      console.warn('preparing data failed', e);
    }

    if (!this.search.query && (!this.view.mediaList || !this.view.mediaList.length)) {
      //this.events.publish('error', 'error_provide_input_or_media');
      console.warn('error_provide_input_or_media');
      return false;
    }

    this.setPhase('loading');

    // if text-to-video is selected and no media is selected, generate images first
    if ((!!this.view.selectedTypesList && !!this.view.selectedTypesList.video) && (!this.view.mediaList || !this.view.mediaList.length)) {
      try {
        this.view.ideas = this.view.mediaList;

        await this.generateCreativesMediaList(this.view.media);

        this.view.loading = false;

        return false;
      } catch (e) {
        console.warn('generating mediaList failed', e);
      }
    }

    this.view.currentIndex = 0;
    this.view.iCurrentImageIndex = 0;
    this.view.iAllErrors = 0;
    this.view.ideas = [];
    this.view.images = (this.view.type === 'video' ? [{}, {}, {}] : [{}, {}, {}, {}, {}, {}]);
    this.view.loading = true;
    this.view.preview_lookup_uids = [];

    this.fireOnViewChanged();

    this.startEstimatedTimeCalcInterval();

    // if creating images, show presenting + pick after 5 seconds
    if (!!this.view.selectedTypesList && !!this.view.selectedTypesList.image) {
      setTimeout(() => {
        this.setPhase('presenting');

        setTimeout(async () => {
          this.setPhase('pick');
        }, (2 * 1000));

      }, (3 * 1000));
    }

    this.executeAiCreateSimultaneously(0, true)
      .then(() => {
        this.view.loading = false;

        this.stopEstimatedTimeCalcInterval();
        this.afterCreation();
      })
      .catch((error: any) => {
        this.view.loading = false;
        console.error('asset creation failed', error);

        this.stopEstimatedTimeCalcInterval();
        this.fireOnViewChanged();
      });
  }

  amountChanged() {
    this.view.blCustomAmount = true;
    this.view.canGenerate = true;

    this.setMaxResults(this.view.aiOptions.amount, true);
  }

  aspectRatioChanged() {
    this.view.aiOptions = this.view.aiOptions || {};
    this.view.canGenerate = true;

    let iWidth: number = 1024, iHeight: number = 1024, fps: number = (this.view.aiOptions.fps || 24);

    switch (this.view.aspect_ratio) {
      case '9x16':
        iHeight = 1024, iWidth = 576;
        break;
      case '16x9':
        iHeight = 576, iWidth = 1024;
        break;
    }

    this.view.aiOptions.fps = fps || 24;
    this.view.aiOptions.height = iHeight || 1024;
    this.view.aiOptions.width = iWidth || 1024;

    // update related variables
    if (!!this.view.aspect_ratio) {
      this.view.filters = this.view.filters || {};
      this.view.filters.aspect_ratios = [this.view.aspect_ratio];

      if (!!this.view.templateView) {
        this.view.templateView.aspectRatio = this.view.aspect_ratio;
      }

    }

    this.fireOnViewChanged();
  }

  asyncGenerateTemplatesIdeasPreviews(ideas: any[]) {

    if (!ideas || !ideas.length) {
      return false;
    }

    this.view.preview_lookup_uids = [];
    this.visibleQueueItemUids = [];
    this.visibleQueueItemUidsIndexes = {};

    let previewLookupUids: number[] = [];

    this.setPhase('loading');

    return new Promise((resolve, reject) => {
      let iDone: number = 0;

      ideas.forEach((idea: any, _index: number) => {
        setTimeout(async () => {
          try {
            idea.config = idea.config || {};
            idea.config.filters = idea.config.filters || {};
            idea.config.filters.types = this.view.selectedTypesList;
            idea.config.filters.quality = 'getgenius_preview'; // this.view.quality || 'auto';

            idea.value = JSON.stringify(idea.config);

            let create: any = await this.media.create(idea);
            iDone++;

            if (!!create.uid) {
              previewLookupUids.push(create.uid);
            }

            if (iDone === ideas.length) {
              resolve(previewLookupUids);
            }

          } catch (e) {
            console.warn('generating live preview failed', e);

            iDone++;

            if (iDone === ideas.length) {
              resolve(previewLookupUids);
            }
          }
        }, (_index + 1) * 50);
      });
    })
      .then((previewLookupUids: number[]) => {
        console.log('previewLookupUids', previewLookupUids);
        this.view.preview_lookup_uids = previewLookupUids;

        this.onTemplatesIdeasPreviewQueueItemsCreated();
      })
      .catch((error: any) => {
        console.warn('generating preview items failed', error);
      });
  }

  backToInputMode() {
    this.setPhase('input');
  }

  calcCurrentTemplates() {
    console.log('calc current templates: all', this.view.all_templates);

    if (!this.view.all_templates) {
      return false;
    }

    const _all: mediaTemplate[] = JSON.parse(JSON.stringify(this.view.all_templates || []));

    let availableTemplates: any[] = _all.filter((template: mediaTemplate) => {
      return (!!this.view.selectedTypesList && !!this.view.selectedTypesList.image) || (template.type === 'video');
    });

    console.log('availableTemplates: before', availableTemplates);

    // if only image templates should be generated, change all templates of type image
    if (!!this.view.selectedTypesList && !this.view.selectedTypesList.video && !!availableTemplates && !!availableTemplates.length) {
      availableTemplates = availableTemplates.map((_template: mediaTemplate) => {
        _template.type = 'image';
        return _template;
      });
    }

    this.view.templates = this.tools.shuffle(availableTemplates || []).slice(0, 30);
    console.log('calc current templates: available', this.view.templates);

    let imageIds: number[] = [], videoIds: number[] = [];

    this.view.templates.forEach((template: mediaTemplate) => {
      if (!!template.uid) {
        if (template.type === 'image') {
          imageIds.push(template.uid);
        } else
          if (template.type === 'video') {
            videoIds.push(template.uid);
          }
      }
    });

    this.view.template_uids = this.view.template_uids || {};
    this.view.template_uids.images = imageIds;
    this.view.template_uids.videos = videoIds;

    console.log('this.view.template_uids', this.view.template_uids);
  }

  calcInputModeStates() {
    this.view.selectedInputModesList = {};

    this.view.selectedInputModes = (this.view.inputModes || []).filter((mode: any) => {
      this.view.selectedInputModesList[mode.uid] = !!mode.checked;

      return !!mode.checked;
    });

    this.view.hasSelectedInputModes = !!this.view.selectedInputModes.length;
  }

  calcTypesStates() {
    this.view.filters = this.view.filters || {};
    this.view.selectedTypesList = {};

    this.view.selectedTypes = (this.view.types || []).filter((type: any) => {
      this.view.selectedTypesList[type.uid] = !!type.checked;

      return !!type.checked;
    });

    this.view.hasSelectedTypes = !!this.view.selectedTypes.length;

    let types: any = [];

    if (!!this.view.selectedTypesList && !!this.view.selectedTypesList.image) {
      this.setMaxResults(6);
      types.push('image');
    }

    if (!!this.view.selectedTypesList && !!this.view.selectedTypesList.video) {
      this.setMaxResults(3);
      types.push('video');
    }

    if (!!this.view.aiOptions && !!this.view.maxResults) {
      this.view.aiOptions.amount = this.view.maxResults || this.view.aiOptions.amount;
    }

    this.view.filters.types = types;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.canCreate = (!!this.view.media && !!this.view.media.title && !!this.view.media.subtitle && !!this.view.mediaList && !!this.view.mediaList.length);
    this.view.canGenerate = (!!this.view.media && !!this.view.media.title && !!this.view.media.subtitle && !!this.view.mediaList && !!this.view.mediaList.length);

    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);

    this.calcInputModeStates();
    this.calcTypesStates();
  }

  chooseMediaFromList(media: any, index: number, list: any = null) {
    list = list || this.view.mediaList;

    if (!media || !media.url) {
      return false;
    }

    if (!!this.view.multiple) {
      if (typeof list[index] === 'string') {
        this.view.selectedStrings[media] = !this.view.selectedStrings[media];
      } else {
        list[index].checked = !list[index].checked;
      }
    } else
      if (!!list) {
        list.forEach((_media: any) => {
          if (typeof _media === 'string') {
            console.warn('_media is string', _media);
          } else {
            let bl: boolean = (((!!media.ID && (media.ID === _media.ID)) || (!!media.original_uid && (media.original_uid === _media.original_uid))) || (!!media.url && (media.url === _media.url)) || (!!media.thumbnail && (media.thumbnail === _media.thumbnail))) && !_media.checked;
            _media.checked = bl;

            if (!!bl) {
              this.view.item = _media;
            }
          }
        });
      }

    if (!!list) {
      this.view.items = list.filter((_media: any) => {
        if (typeof _media === 'string') {
          return !!this.view.selectedStrings[_media];
        } else
          if (!!_media) {
            return !!_media.checked;
          }
      });
    }

    let selectedStringsKeys: string[] = Object.keys(this.view.selectedStrings || []);

    let selectedItems: any[] = this.view.items.map((item: any) => {
      item.photo = item.photo || item.url;
      item.thumbnail = item.thumbnail || item.photo;

      return item;
    });

    if (!!selectedStringsKeys && !!selectedStringsKeys.length) {
      let _mediaList: any[] = [];

      selectedStringsKeys.forEach((url: string) => {
        if (!!this.view.selectedStrings[url]) {
          _mediaList.push({
            checked: true,
            photo: `${url}`,
            thumbnail: `${url}`,
          });
        }
      });

      this.view.mediaList = _mediaList;
    } else {
      this.view.mediaList = selectedItems;
    }

    this.onSelectionChanged.emit({
      item: this.view.item,
      items: selectedItems,
      mediaList: this.view.mediaList,
    });

    this.fireOnViewChanged();
  }

  chooseModel() {
    this.aiTools.chooseModel({
      filter: {
        category: 'TXT2IMG',
      },
      multiple: true,
    })
      .then((model: any) => {
        if (!!model) {
          this.view.aiOptions.model = model;
          this.aiWorker.setConfig(this.view.aiOptions);
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  colorChanged(event: any = null, _target: any = null) {
    _target = event;
    this.updatePreview();
  }

  editImage(image: any) {
    console.warn('edit image', image);
    return false;
  }

  executeAiCreate(index: number = 0, blForceRefresh: boolean = false, blOnlyCurrent: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.aiOptions = this.view.aiOptions || {};
      this.view.currentIndex = this.view.currentIndex || 0;
      this.view.iCurrentImageIndex = this.view.iCurrentImageIndex || 0;
      this.view.loading = true;

      let iWidth: number = (this.view.aiOptions.width || (this.view.type === 'video' ? 768 : 1024)),
        iHeight: number = (this.view.aiOptions.height || (this.view.type === 'video' ? 768 : 1024)),
        fps: number = (this.view.aiOptions.fps || 24);

      if (!this.view.aiOptions || !this.view.aiOptions.width || !this.view.aiOptions.height) {
        switch (this.view.aspect_ratio) {
          case '9x16':
            iHeight = 1024, iWidth = 576;
            break;
          case '16x9':
            iHeight = 576, iWidth = 1024;
            break;
        }

        this.view.aiOptions.fps = fps;
        this.view.aiOptions.height = iHeight;
        this.view.aiOptions.width = iWidth;
      }

      // set aspect ratio to 1024x576px for stable video diffusion
      if (!!this.view.selectedTypesList && !!this.view.selectedTypesList.video) {
        this.view.aiOptions.height = 576;
        this.view.aiOptions.width = 1024;
      }

      this.view.images[this.view.iCurrentImageIndex] = { timestamp: new Date().toISOString(), estimated_time: 120 };
      this.view.maxResults = (this.view.aiOptions.amount || (this.view.maxResults || 3));
      this.view.maxErrors = (this.view.maxResults * 2);

      this.fireOnViewChanged();

      const options: any = Object.assign(this.view.aiOptions, {
        blFineTuneInput: !!this.view.blFineTuneInput,
        creative: true,
        limit: 1,
        mediaList: (this.view.mediaList || []).map((mediaItem: mediaItem) => {
          return (mediaItem.thumbnail || mediaItem.guid);
        }),
        query: `${this.search.query}`,
        style: (this.view.style || {}),
      });

      if (!this.view.selectedTypes || !this.view.selectedTypes.length) {
        return false;
      }

      this.view.selectedTypes.forEach((type: any, typeIndex: number) => {
        setTimeout(() => {
          switch (type.uid) {
            case 'video':
              options.request = 'videos';
              this.executeAiCreateVideos(options, index, blForceRefresh, blOnlyCurrent).then(resolve).catch(reject);
              break;
            default:
              options.request = 'images';
              this.executeAiCreateImages(options, index, blForceRefresh, blOnlyCurrent).then(resolve).catch(reject);
              break;
          }
        }, (typeIndex * 500));
      });

    });
  }

  executeAiCreateImages(options: any, index: number = 0, blForceRefresh: boolean = false, blOnlyCurrent: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.images[this.view.iCurrentImageIndex] = { timestamp: new Date().toISOString(), estimated_time: 15, };

      this.aiTools.search(options, {}, true)
        .then((response: any) => {
          this.view.loading = false;

          if (!!response && !!response.suggestions && !!response.suggestions.length) {
            response.suggestions.forEach((suggestion: any) => {
              suggestion.type = 'image';
              this.view.images[this.view.iCurrentImageIndex] = suggestion;
              this.view.iCurrentImageIndex++;
            });
          } else
            if (!!response && !!response.images && !!response.images.length) {
              response.images.forEach((image: any) => {

                if (typeof image === 'string') {
                  this.view.images[this.view.iCurrentImageIndex].photo = image;
                  this.view.images[this.view.iCurrentImageIndex].type = 'image';
                  this.view.images[this.view.iCurrentImageIndex].url = image;
                } else {
                  this.view.images[this.view.iCurrentImageIndex] = image;
                }

                this.view.iCurrentImageIndex++;
              });
            }

          this.view.currentIndex = (index + 1);

          if (!!blOnlyCurrent) {
            resolve(this.view);
          } else
            if (this.view.maxResults >= this.view.iCurrentImageIndex) {
              return this.executeAiCreateImages(options, this.view.iCurrentImageIndex, blForceRefresh, blOnlyCurrent).then(resolve).catch(reject);
            } else {
              this.view.iCurrentImageIndex = null;
              this.view.loading = false;

              resolve(this.view);
            }
        })
        .catch((error: any) => {
          this.view.iAllErrors++;
          console.warn('> error #', this.view.iAllErrors, error);

          if (this.view.iAllErrors >= this.view.maxErrors) {
            this.view.currentIndex = null;
            this.view.loading = false;
            reject(error);
          } else
            if (this.view.maxResults >= index) {
              setTimeout(async () => {
                this.executeAiCreateImages(options, index, blForceRefresh, blOnlyCurrent).then(resolve).catch(reject);
              }, 500);
            }

          this.view.currentIndex = null;
          this.view.loading = false;

          reject(error);
        });
    })
  }

  executeAiCreateVideos(options: any, index: number = 0, blForceRefresh: boolean = false, blOnlyCurrent: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.images[this.view.iCurrentImageIndex] = { timestamp: new Date().toISOString(), estimated_time: 120, };

      this.aiTools.search(options, {}, true)
        .then((response: any) => {
          this.view.loading = false;

          if (!!response && !!response.suggestions && !!response.suggestions.length) {
            response.suggestions.forEach((suggestion: any) => {
              this.view.preview_lookup_uids.push(suggestion.uid);

              suggestion.type = 'video';

              this.view.images[this.view.iCurrentImageIndex] = suggestion;
              this.view.iCurrentImageIndex++;
            });
          } else
            if (!!response && !!response.videos && !!response.videos.length) {
              response.videos.forEach((video: any) => {

                this.view.images[this.view.iCurrentImageIndex] = {
                  type: 'video',
                  url: video,
                };

                this.view.iCurrentImageIndex++;
              });
            }

          this.view.currentIndex = (index + 1);

          this.fireOnViewChanged();

          if (!!blOnlyCurrent) {
            resolve(this.view);
          } else
            if (this.view.maxResults >= this.view.currentIndex) {
              return this.executeAiCreateVideos(options, this.view.currentIndex, blForceRefresh, blOnlyCurrent).then(resolve).catch(reject);
            } else {
              this.view.currentIndex = null;
              this.view.loading = false;

              this.fireOnViewChanged();
              resolve(this.view);
            }

        })
        .catch((error: any) => {
          this.view.iAllErrors++;
          console.warn('> error #', this.view.iAllErrors, error);

          if (this.view.iAllErrors >= this.view.maxErrors) {
            this.view.currentIndex = null;
            this.view.loading = false;
            reject(error);
          } else
            if (this.view.maxResults >= index) {
              return this.executeAiCreateVideos(options, index, blForceRefresh, blOnlyCurrent).then(resolve).catch(reject);
            }

          this.view.currentIndex = null;
          this.view.loading = false;

          reject(error);
        });
    })
  }

  executeAiCreateSimultaneously(index: number = 0, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      let iCurrent: number = 0, _i: number = 0, blStop: boolean = false;

      this.view.maxResults = (this.view.aiOptions.amount || (this.view.maxResults || 3));
      this.view.finishedExecutions = 0;
      this.view.creation_responses = [];
      this.view.preview_lookup_uids = [];

      if (!!this.view.aiOptions && !!this.view.maxResults) {
        this.view.aiOptions.amount = this.view.aiOptions.amount || this.view.maxResults;
      }

      while ((this.view.maxResults > iCurrent) && !blStop) {
        iCurrent++;

        try {
          setTimeout(() => {
            this.view.images[_i] = { timestamp: new Date().toISOString(), estimated_time: ((this.view.type === 'video' ? 120 : 5) + (_i * 8)) };

            this.executeAiCreate(_i, blForceRefresh, true)
              .then((response: any) => {
                this.view.finishedExecutions++;
                this.view.creation_responses.push(response);

                if (this.view.finishedExecutions === this.view.maxResults) {
                  resolve(this.view);
                }
              })
              .catch((error: any) => {
                this.view.finishedExecutions++;

                if (this.view.finishedExecutions === this.view.maxResults) {
                  resolve(this.view);
                }
              });

            _i++;
          }, ((iCurrent + 1) * 500));
        } catch (e) {
          console.warn('part failed', e);
          this.view.finishedExecutions++;
        }
      }
    });
  }

  fireOnViewChanged() {
    this.onViewChanged.emit(this.view);
  }

  async generate() {
    if (!!this.view.media && (!!this.view.media.subtitle || !!this.view.media.title)) {
      // if overlay is set, create creatives
      return this.generateCreatives();
    } else {
      // else, create assets
      return this.aiCreate();
    }
  }

  async generateCreatives() {

    // if preview items selected, render preview items
    if ((this.view.phase === 'preview') && (!!this.view && !!this.view.selectedItems && !!this.view.selectedItems.length)) {
      return this.addToQueue({
        quality: 'default',
      });
    }

    this.setPhase('loading');
    this.startManually();

    this.view.loading = true;
    this.view.media.tuning = {};

    // if text fine-tuning is selected, optimize text inputs
    if (!!this.view.media.blFineTuneInput) {
      try {
        let fineTune: any = await this.media.fineTuneMediaInput(this.view.media);

        if (!!fineTune.subtitles || !!fineTune.titles) {
          this.view.media.tuning = {
            subtitles: [this.view.media.subtitle].concat(this.tools.shuffle(fineTune.subtitles || [])),
            titles: [this.view.media.title].concat(this.tools.shuffle(fineTune.titles || [])),
          };
        }

      } catch (e) {
        console.warn('fine-tuning input failed', e);
      }
    }

    // if no media is selected, generate images first (@todo add video generation here too for video-in-video generation)
    if (!this.view.mediaList || !this.view.mediaList.length) {
      try {
        this.view.ideas = this.view.mediaList;

        await this.generateCreativesMediaList(this.view.media);

        this.view.loading = false;

        return false;
      } catch (e) {
        console.warn('generating mediaList failed', e);
      }
    }

    // then, generate the creatives
    this.templates
      .bulkGenerateTemplates([this.view.media], this.view)
      .then((items: any) => {
        console.log('calculated template items', items);

        this.zone.run(() => {

          let ideas: any[] = this.tools.shuffle(items.map((item: any) => {
            let blCustom: boolean = (item.hasOwnProperty('custom') ? item.custom : (!!item.template ? !!item.template.custom : true));

            item.config = JSON.parse(item.value);
            item.custom = blCustom;
            item.uid = item.uid || item.template_uid;
            item.view = item.view || JSON.parse(JSON.stringify(this.view.templateView));

            return item;
          })).slice(0, 100);

          this.view.loading = false;

          this.asyncGenerateTemplatesIdeasPreviews(ideas);
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  generateCreativesMediaList(options: any, iLimit: number = 3) {
    return new Promise((resolve, reject) => {

      let blHasResult: boolean = false, iCurrent: number = 0,
        query: string;

      this.view.iCurrentImageIndex = 0;
      this.view.images = [{}, {}, {}];
      this.view.loading = true;

      // set aspect ratio to 1024x576px for stable video diffusion
      if (!!this.view.selectedTypesList && !!this.view.selectedTypesList.video) {
        this.view.aiOptions.height = 576;
        this.view.aiOptions.width = 1024;
      }

      while (iCurrent < iLimit) {
        iCurrent++;

        try {

          setTimeout(() => {

            if (!!options.title || !!options.subtitle) {
              query = `${options.title || ''}\n${options.subtitle || ''}`;
            } else {
              query = `${this.search.query || ''}`;
            }

            let searchOptions: any = Object.assign(options, {
              creative: true,
              limit: 1,
              query: query,
              request: 'images',
              style: (this.view.style || {}),

              // apply aspect ratio
              height: this.view.aiOptions.height,
              width: this.view.aiOptions.width,
            });

            this.aiTools.search(searchOptions, {}, true)
              .then((response: any) => {
                if (!!response && !!response.suggestions && !!response.suggestions.length) {
                  response.suggestions.forEach((suggestion: any) => {

                    // switch to extend view if first result is loaded (shows loading dani before)
                    if (!blHasResult) {
                      blHasResult = true;
                      this.setPhase('extend');
                    }

                    this.view.images[this.view.iCurrentImageIndex] = suggestion;
                    this.view.iCurrentImageIndex++;
                  });
                } else
                  if (!!response && !!response.images && !!response.images.length) {
                    response.images.forEach((image: any) => {

                      // switch to extend view if first result is loaded (shows loading dani before)
                      if (!blHasResult) {
                        blHasResult = true;
                        this.setPhase('extend');
                      }

                      let media: any = {
                        active: true,
                        checked: true,
                        photo: image,
                        thumbnail: image,
                        type: 'image',
                        uid: iCurrent,
                        url: image,
                      };

                      this.view.images[this.view.iCurrentImageIndex] = media;
                      this.view.iCurrentImageIndex++;
                    });
                  }

                if (this.view.iCurrentImageIndex === iLimit) {
                  this.view.loading = false;
                  resolve(this.view.images);
                }

              })
              .catch((error: any) => {
                console.warn('generating image failed', error);

                this.view.iCurrentImageIndex++;

                if (this.view.iCurrentImageIndex === iLimit) {
                  this.view.loading = false;
                  resolve(this.view.images);
                }

              });

          }, (iCurrent * 150));

        } catch (e) {
          this.view.iCurrentImageIndex++;

          if (this.view.iCurrentImageIndex === iLimit) {
            this.view.loading = false;
            resolve(this.view.images);
          }

        }
      }

    });
  }

  industryUIChanged() {
    //console.log('industryUIChanged', this.view);
  }

  initEvents() {
    this.view.events = {};

    this.view.events.mediaCreatorAddToQueue = this.events.subscribe('media:creator:addToQueue', () => {
      this.addToQueue();
    });

    this.view.events.mediaCreatorRun = this.events.subscribe('media:creator:run', (data: any) => {
      this.generate();
    });

    this.view.events.mediaCreatorUpdate = this.events.subscribe('media:creator:update', (view: any) => {
      if (!!view) {

        // @debug (fix col size, improve this)
        view.colSize = (!!this.view.isDesktop ? 4 : 12);

        this.view = view;
      }
    });

    this.view.events.mediaCreatorWatchQueue = this.events.subscribe('media:creator:watchQueue', () => {
      this.watchQueue();
    });

    this.view.events.projectCurrentChanged = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.projectChanged();
    });

  }

  initViewVars() {

    this.view.mediaCreatorIntroCard = {
      hidden: false,
      uid: 'create_media_input_top_card',
      text: 'create_media_input_text',
      title: 'create_media_input_headline',
    };

    this.view.blFineTuneInput = (this.view.hasOwnProperty('blFineTuneInput') ? !!this.view.blFineTuneInput : true);
    this.view.currentIndex = this.view.currentIndex || 0;
    this.view.images = this.view.images || [];
    this.view.industryUI = this.view.industryUI || 'default';
    this.view.loadingOptions = this.view.loadingOptions || {};
    this.view.maxResults = (!!this.view.aiOptions && !!this.view.aiOptions.amount ? this.view.aiOptions.amount : (this.view.maxResults || 3));
    this.view.media = this.view.media || { title: '', subtitle: '' };
    this.view.multiple = (this.view.hasOwnProperty('multiple') ? !!this.view.multiple : true);
    this.view.phase = this.view.phase || 'input';
    this.view.selectedStrings = this.view.selectedStrings || [];

    this.view.aiOptions = this.view.aiOptions || {
      fps: 24,
      height: 1024,
      width: 1024,
    };

    // prepare aspect ratios

    this.view.aspect_ratio = this.view.aspect_ratio || '1x1';

    this.view.aspect_ratios = this.view.aspect_ratios || [
      {
        icon: 'tablet-landscape-outline',
        uid: '16x9',
        name: 'aspect_ratio_landscape',
      },
      {
        checked: true,
        icon: 'square-outline',
        uid: '1x1',
        name: 'aspect_ratio_square',
      },
      {
        icon: 'tablet-portrait-outline',
        uid: '9x16',
        name: 'aspect_ratio_portrait',
      },
    ];

    // prepare button action
    this.view.buttonAction = this.view.buttonAction || 'generate';

    // prepare filters

    this.view.filters = this.view.filters || {
      aspect_ratios: ['1x1'],
      colors: ['light', 'dark'],
      platforms: ['facebook', 'instagram', 'tiktok', 'youtube'],
      types: ['image', 'video'],
    };

    // input modes
    this.view.inputMode = this.view.inputMode || 'text';
    this.view.inputModes = this.view.inputModes || [
      {
        checked: true,
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ];

    // prepare media object
    this.view.media = this.view.media || {};

    // prepare platforms
    this.view.platforms = this.view.platforms || [
      {
        uid: 'facebook',
        name: 'facebook',
      },
      {
        uid: 'instagram',
        name: 'instagram',
      },
      {
        uid: 'tiktok',
        name: 'tiktok',
      },
      {
        uid: 'youtube',
        name: 'youtube',
      }
    ];

    // prepare qualities

    this.view.quality = this.view.quality || 'default';

    this.view.qualities = this.view.qualities || [
      {
        uid: 'preview',
        name: 'quality_preview',
      },
      {
        uid: 'default',
        name: 'quality_default',
      },
      /*
      {
        uid: 'full',
        name: 'quality_full',
      },
      */
    ];

    this.view.queueTimeIndex = this.view.queueTimeIndex || 1;

    // prepare resolutions

    this.view.resolution = this.view.resolution || 720;

    this.view.resolutions = this.view.resolutions || [
      {
        uid: 360,
        name: '360p',
      },
      {
        uid: 480,
        name: '480p',
      },
      {
        uid: 720,
        name: '720p',
      },
      {
        uid: 1080,
        name: 'Full HD',
      },
      {
        uid: 2560,
        name: '2k',
      },
      {
        uid: 4096,
        name: '4k',
      },
    ];

    // prepare selection options
    this.view.selectionOptions = this.view.selectionOptions || [
      {
        icon: 'cloud-download-outline',
        label: 'import',
        uid: 'import',
      },
      {
        icon: 'expand-outline',
        label: 'upscale',
        uid: 'upscale',
      },
      {
        icon: 'film-outline',
        label: 'create_video_assets',
        uid: 'video_assets',
      },
    ];

    // prepare template view
    this.view.templateView = this.view.templateView || {
      aspectRatio: '1x1',
      editable: false,
      infinite: true,
      running: false,
      maxWidth: 300,
    };

    // prepare types
    this.view.type = this.view.type || 'all';
    this.view.types = this.view.types || [
      {
        checked: true,
        icon: 'image-outline',
        uid: 'image',
        name: 'image',
      },
      {
        icon: 'film-outline',
        uid: 'video',
        name: 'video',
      }
    ];

  }

  itemInfo(image: any, event: any = null) {
    console.log('get item info', image);

    this.view.item = image;

    this.itemInfoPopover.event = event;
    this.isItemInfoPopoverOpen = true;
  }

  loadAIVideosQueue() {
    return new Promise((resolve, reject) => {
      if (!this.view.preview_lookup_uids || !this.view.preview_lookup_uids.length) {
        reject('error_missing_preview_lookup_uids');
      } else {

        this.view.loadingQueue = true;

        let iCount: number = (!!this.view.preview_lookup_uids ? this.view.preview_lookup_uids.length : 0);

        this.sd.getQueue(true, {
          filter: {
            user_uid: this.userService.getUid(),
          },
          lookup_uids: (this.view.preview_lookup_uids || []),
        })
          .then((queue: mediaQueueItem[]) => {

            try {

              queue = queue.map((item: any) => {
                item.view = item.view || JSON.parse(JSON.stringify(this.view.templateView));

                return item;
              });

              // sort queue by states + url existence
              queue.filter((queueItem: mediaQueueItem) => {
                return (queueItem.state !== 'failed') && !((queueItem.state === 'done') && !queueItem.url);
              })
                .sort((a, b) => this.stateKeys.indexOf(b.state) - this.stateKeys.indexOf(a.state))
                .sort((a, b) => {
                  if (a.url && b.url) {
                    return 0;
                  } else if (a.url) {
                    return -1;
                  } else {
                    return 1;
                  }
                });

              this.view.maxResults = iCount;

              if (!!this.view.aiOptions && !!this.view.maxResults) {
                this.view.aiOptions.amount = this.view.aiOptions.amount || this.view.maxResults;
              }

              let blHasChanges: boolean = !!this.view.forceRefresh || !!(!this.view.last_state || (JSON.stringify(queue) !== this.view.last_state));

              console.log('ai videos queue', queue);

              if (blHasChanges) {
                this.view.last_state = JSON.stringify(queue);

                // add new queue items to view
                this.addNewQueueItemsFromResponseToIdeasList(queue);
              }

              this.view.loadingQueue = false;
              resolve(this.view);
            } catch (e) {
              console.warn('updating queue failed', e);
              resolve(this.view);
            }
          })
          .catch((error: any) => {
            this.view.loadingQueue = false;
            reject(error);
          });

      }
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
    this.projectChanged();
  }

  loadTemplates(blForceRefresh: boolean = false, options: any = {}) {
    return new Promise((resolve, reject) => {

      options.filter = options.filter || {
        active: true,
        public: false,  // debug for all: 1,
      };

      options.project_uid = (options.hasOwnProperty('project_uid') ? options.project_uid : (!!this.view.project && !!this.view.project.uid ? this.view.project.uid : null));
      // debug for all: 0

      console.log('load templates: options', JSON.parse(JSON.stringify(options)));

      this.templates.get(blForceRefresh, options)
        .then((templates: mediaTemplate[]) => {
          console.log('templates', templates);

          if (!templates || !templates.length) {
            this.view.templates = [];

            // handle no templates response
            if (!!options.project_uid) {
              // first, load all templates then
              return this.loadTemplates(blForceRefresh, {
                filter: {
                  active: true,
                  public: true,
                },
                project_uid: 0,
              });
            } else
              if (!this.view.blocked) {
                // then, show setup dialog
                this.view.blocked = true;
                this.templates.showNoTemplatesAlert();
              }

            setTimeout(() => {
              this.view.blocked = false;
            }, 1000);

            return false;
          }

          this.view.all_templates = (templates || []);

          this.calcCurrentTemplates();

          resolve(this.view);
        })
        .catch(reject);
    });
  }

  loadTemplatesPreviewQueue() {
    return new Promise((resolve, reject) => {
      if (!this.view.preview_lookup_uids || !this.view.preview_lookup_uids.length) {
        reject('error_missing_preview_lookup_uids');
      } else {

        this.view.loadingQueue = true;

        let iCount: number = (!!this.view.preview_lookup_uids ? this.view.preview_lookup_uids.length : 0);

        this.media.getQueue(true, {
          filter: {
            user_uid: this.userService.getUid(),
          },
          lookup_uids: (this.view.preview_lookup_uids || []),
        })
          .then((queue: mediaQueueItem[]) => {
            console.log('queue', queue);

            try {

              queue = queue.map((item: any) => {
                item.view = item.view || JSON.parse(JSON.stringify(this.view.templateView));

                return item;
              });

              // sort queue by states + url existence
              queue.filter((queueItem: mediaQueueItem) => {
                return (queueItem.state !== 'failed') && !((queueItem.state === 'done') && !queueItem.url);
              })
                .sort((a, b) => this.stateKeys.indexOf(b.state) - this.stateKeys.indexOf(a.state))
                .sort((a, b) => {
                  if (a.url && b.url) {
                    return 0;
                  } else if (a.url) {
                    return -1;
                  } else {
                    return 1;
                  }
                });

              this.view.maxResults = iCount;

              let blHasChanges: boolean = !!this.view.forceRefresh || !!(!this.view.last_state || (JSON.stringify(queue) !== this.view.last_state));

              if (blHasChanges) {
                this.view.last_state = JSON.stringify(queue);

                // add new queue items to view
                this.addNewQueueItemsFromResponseToIdeasList(queue);
              }

              this.view.loadingQueue = false;
              resolve(this.view);
            } catch (e) {
              console.warn('updating queue failed', e);
              resolve(this.view);
            }
          })
          .catch((error: any) => {
            this.view.loadingQueue = false;
            reject(error);
          });

      }
    });
  }

  ngAfterViewInit() {
    this.setPhase('input');
  }

  ngOnDestroy() {

    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    this.stopEstimatedTimeCalcInterval();
  }

  ngOnInit() {
    this.initEvents();
    this.initViewVars();

    this.calcViewVars();

    this.loadProject();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  async onAIVideosQueueItemsCreated() {
    this.view.images = [];

    this.calcViewVars();

    if (!this.view.preview_lookup_uids || !this.view.preview_lookup_uids.length) {
      return false;
    }

    if (!!this.view.loadAIVideosQueueInterval) {
      clearInterval(this.view.loadAIVideosQueueInterval);
    }

    this.view.last_state = null;

    this.setPhase('loading');

    // after 30 seconds, show pick UI and check for queue updates every 5 seconds
    setTimeout(() => {
      this.view.images = [];

      this.view.loadAIVideosQueueInterval = setInterval(async () => {
        try {
          await this.loadAIVideosQueue();
        } catch (e) {
          console.warn('loading templates preview queue failed', e);
        }
      }, (5 * 1000));

      setTimeout(() => {
        // @todo: if text-overlay is present, show pick first
        this.setPhase('queue');
      }, 5000);

    }, (30 * 1000));
  }

  onCreated() {
    this.view.images = [];
    this.setPhase('presenting');

    setTimeout(async () => {
      this.setPhase('pick');
    }, 5000);

    this.calcViewVars();
  }

  onInputChanged(event: any = null) {
    this.view.canGenerate = true;

    this.zone.run(() => {
      this.view.loading = false;
    });
  }

  onItemCheckboxClicked(image: any, i: number) {
    let buttonAction: string = `${this.view.buttonAction}`;

    image.checked = !image.checked;

    this.chooseMediaFromList(image, i, this.view.images);

    setTimeout(() => {
      if (!!buttonAction) {
        this.view.buttonAction = buttonAction;
      }
    });
  }

  onLanguageChange(event: any = null) {
    this.view.canGenerate = true;
  }

  onMusicSettingsChanged(settings: any) {
    this.view.canGenerate = true;

    /*
    let blUseTts: boolean = (!!settings && !!settings.use_tts);

    if(!!this.view && !!this.view.templates) {
      this.view.templates = this.view.templates.map((template: mediaTemplate) => {

        if(!!template && !!template.config && !!template.config.Comp) {
          template.config.Comp.text2voice = (!!blUseTts ? 1 : 0);

          template.config._meta = template.config._meta || {};
          template.config._meta.tts = settings;
        }

        return template;
      });

      console.log('updated templates', this.view.templates);
    }

    this.updatePreview();
    */
  }

  async onTemplatesIdeasPreviewQueueItemsCreated() {
    console.log('onTemplatesIdeasPreviewQueueItemsCreated', this.view);

    this.view.images = [];
    this.calcViewVars();

    if (!this.view.preview_lookup_uids || !this.view.preview_lookup_uids.length) {
      return false;
    }

    if (!!this.view.loadTemplatesPreviewQueueInterval) {
      clearInterval(this.view.loadTemplatesPreviewQueueInterval);
    }

    this.view.last_state = null;

    // after 15 seconds, show pick UI and check for queue updates every 5 seconds
    setTimeout(() => {

      this.view.loadTemplatesPreviewQueueInterval = setInterval(async () => {
        try {
          await this.loadTemplatesPreviewQueue();
        } catch (e) {
          console.warn('loading templates preview queue failed', e);
        }
      }, (5 * 1000));

      setTimeout(() => {
        this.setPhase('presenting');

        setTimeout(() => {
          this.setPhase('preview');
          // simplified, @todo: only use this method for preview rendering, then proceed with full quality rendering

          /*
          if (!!this.view.quality && (this.view.quality === 'preview')) {
            // only show preview screen, then render in selected quality in next step
            this.setPhase('preview');
          } else {
            this.setPhase('queue');
          }
          */
        }, 5000);

      }, 5000);

    }, (15 * 1000));
  }

  onOverlayFormViewChanged(event: any = null) {

    if (!event) {
      return false;
    }

    if (!!event && !!event.media) {
      this.view.media = event.media;
    }

    if (!!event && !!event.sections && !!event.sections[0]) {
      this.view.media.subtitle = `${event.sections[0].subtext || ''}`;
      this.view.media.title = `${event.sections[0].title || ''}`;
    }

    this.onMediaChanged.emit(this.view.media);
  }

  onTemplateCheckboxClicked(template: mediaTemplate, i: number) {

  }

  onVisualSpeakersSettingsChanged(settings: any) {
    this.updatePreview();
  }

  onVoiceoverSettingsChanged(settings: any) {
    let blUseTts: boolean = (!!settings && !!settings.use_tts);

    if (!!this.view && !!this.view.templates) {
      this.view.templates = this.view.templates.map((template: mediaTemplate) => {

        if (!!template && !!template.config && !!template.config.Comp) {
          template.config.Comp.text2voice = (!!blUseTts ? 1 : 0);

          template.config._meta = template.config._meta || {};
          template.config._meta.tts = settings;
        }

        return template;
      });
    }

    this.updatePreview();
  }

  openImage(image: any) {

    if (!image.url) {
      return false;
    }

    // if image is url, just open it
    if (image.url.indexOf('http') === 0) {
      this.browser.create(image.url);
      return false;
    }

    // else, import media first (to support base64 files)
    this.media.importFromUrl(image.url)
      .then((response: any) => {
        if (!!response && !!response.file) {

          // open saved image
          this.browser.create(`${response.file.url || image.url}`);

          // delete linked media item after opening the file
          if (!!response.file.uid) {
            this.media.deleteMediaItem(response.file.uid);
          }

        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  projectChanged() {

    try {

      if (!!this.view.project && !!this.view.project.uid) {
        this.view.media.logo_src = (!!this.view.project.photo ? this.view.project.photo : null);

        if (!!this.view.project.title) {
          this.view.media.source = this.view.project.title;
        }

        if (!!this.view.project.config && !!this.view.project.config.Comp && !!this.view.project.config.Comp.accent && !!this.view.project.config.Comp.accent.color) {
          this.view.media.logo_bg_color = this.view.project.config.Comp.accent.color;
        }

        this.view.selectType = 'project';
      } else {
        this.view.selectType = 'custom';
      }

    } catch (e) {
      console.warn('setting project media data failed', e);
    }

    this.loadTemplates(true)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  qualityChanged() {

  }

  resolutionChanged() {

  }

  saveImage(image: any) {

    if (!image.url) {
      return false;
    }

    this.media.importFromUrl(image.url)
      .then((response: any) => {
        image.downloaded = true;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    return false;
  }

  scrollMediaContentUp() {
    try {
      this.mediaCreatorContent.nativeElement.scrollTop = 0;
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  scrollSidebarDown() {

    if (!!this.tools.isDesktop()) {
      return false;
    }

    try {
      this.sidebar.nativeElement.scrollTop = this.sidebar.nativeElement.scrollHeight;
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  scrollSidebarUp() {
    try {
      this.sidebar.nativeElement.scrollTop = 0;
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  searchTemplates() {

  }

  selectedMediaItemsChanged(items: any[]) {
    this.view.selectedItems = (items || []);
    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.selectedItemsChanged.emit(this.view.selectedItems);

    this.view.buttonAction = 'use';

    this.calcViewVars();
  }

  setPhase(phase: string) {
    this.view.phase = phase;

    this.view.mediaCreatorIntroCard = {
      dismissable: false,
      hidden: false,
      uid: `create_media_${phase}_top_card`,
      text: `create_media_${phase}_text`,
      title: `create_media_${phase}_headline`,
    };

    this.view.loadingOptions = this.view.loadingOptions || {};

    switch (phase) {
      case 'extend':
        this.view.buttonAction = 'use';
        this.view.canGenerate = false;
        this.view.showSplineView = false;

        // hide sidebar cards
        this.cards.types.open = false;
        this.cards.inputMode.open = false;
        this.scrollSidebarUp();

        break;
      case 'input':
        this.view.buttonAction = 'generate';
        this.view.canGenerate = false;
        this.view.loadingOptions.url = './assets/spline/dani/dani_idle.splinecode';
        this.view.showSplineView = !!(this.tools.isDesktop());

        // show sidebar cards
        this.cards.types.open = true;
        this.cards.information.open = true;
        this.cards.inputMode.open = true;
        this.scrollSidebarUp();

        break;
      case 'loading':
        this.view.buttonAction = 'generate';
        this.view.canGenerate = false;
        this.view.loadingOptions.url = './assets/spline/dani/dani_working.splinecode';
        this.view.showSplineView = true;

        // hide sidebar cards
        this.cards.types.open = false;
        this.cards.inputMode.open = false;
        this.scrollSidebarUp();

        break;
      case 'pick':
        this.view.buttonAction = 'generate';
        this.view.canGenerate = false;
        this.view.showSplineView = false;

        // hide sidebar cards
        this.cards.types.open = false;
        this.cards.inputMode.open = false;
        this.scrollSidebarUp();

        break;
      case 'presenting':
        this.view.buttonAction = 'generate';
        this.view.canGenerate = false;
        this.view.loadingOptions.url = './assets/spline/dani/dani_presenting.splinecode';
        this.view.showSplineView = true;

        // hide sidebar cards
        this.cards.types.open = false;
        this.cards.information.open = false;
        this.cards.inputMode.open = false;
        this.scrollSidebarUp();

        break;
      case 'preview':
        this.view.buttonAction = 'use';
        this.view.canGenerate = false;
        this.view.showSplineView = false;

        // hide sidebar cards
        this.cards.types.open = false;
        this.cards.information.open = false;
        this.cards.inputMode.open = false;
        this.scrollSidebarUp();

        break;
      case 'queue':
        this.view.buttonAction = 'use';
        this.view.canGenerate = false;
        this.view.showSplineView = false;

        // hide sidebar cards
        this.cards.types.open = false;
        this.cards.information.open = false;
        this.cards.inputMode.open = false;
        this.scrollSidebarUp();

        break;
      default:
        this.view.buttonAction = 'generate';
        this.view.loadingOptions.url = './assets/spline/dani/dani.splinecode';
        this.view.showSplineView = !!(this.tools.isDesktop());
        break;
    }

    this.onPhaseChanged.emit(phase);

    this.scrollMediaContentUp();

    try {
      if (!!this.view.showSplineView) {
        this.splineViewer.updateAnimation(this.view.loadingOptions);
      }
    } catch (e) {
      console.warn('updating spline viewer failed', e);
    }

  }

  setMaxResults(iMax: number, blForce: boolean = false) {
    if (!!blForce || !this.view.blCustomAmount) {
      this.view.maxResults = iMax;

      this.view.aiOptions = this.view.aiOptions || {};
      this.view.aiOptions.amount = iMax;
    }
  }

  startEstimatedTimeCalcInterval() {
    this.view.estimatedTimeCalcInterval = setInterval(() => {

      if (!this.view.images || !this.view.images.length) {
        this.stopEstimatedTimeCalcInterval();
        return false;
      }

      this.view.images.forEach((image: any) => {
        if (!!image && !!image.estimated_time && (image.estimated_time > 0)) {
          image.estimated_time = `${parseInt(image.estimated_time) - 1}`;
        }
      });

    }, 1000);
  }

  startManually() {
    this.view.startManually = true;

    this.calcInputModeStates();
    this.calcTypesStates();
  }

  stopEstimatedTimeCalcInterval() {
    try {
      if (!!this.view.estimatedTimeCalcInterval) {
        clearInterval(this.view.estimatedTimeCalcInterval);
      }
    } catch (e) {
    }
  }

  thumbnailLoadingFailed(item: any = null) {
    if (!!item) {
      item.photo = this.fallbackImg;
    } else {
      this.view.media.logo_src = this.fallbackImg;
    }
  }

  toggleAspectRatio(aspect_ratio: any, iAspectRatio: number) {

    // legacy, use multi support:
    this.view.aspect_ratios.forEach((_aspect_ratio: any) => {
      _aspect_ratio.checked = false;
    });

    this.view.aspect_ratio = aspect_ratio.uid;
    aspect_ratio.checked = !aspect_ratio.checked;

    this.aspectRatioChanged();
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;
  }

  toggleFineTune() {
    this.fireOnViewChanged();
  }

  toggleInputMode(mode, iMode) {
    this.view.inputModes[iMode].checked = !this.view.inputModes[iMode].checked;

    this.calcInputModeStates();
    this.scrollSidebarDown();

    setTimeout(() => {
      try {
        console.log('mediaPicker', this.mediaPicker);

        // auto-open media picker dialog if no media selected
        if (!!mode && !!mode.uid && (mode.uid === 'media' && (!this.view.mediaList || !this.view.mediaList.length))) {
          this.mediaPicker.add();
        }
      } catch (e) {
        console.warn('opening media picker failed', e);
      }
    });
  }

  toggleType(type: any, iType: number) {
    this.view.types[iType].checked = !this.view.types[iType].checked;

    this.calcTypesStates();
    this.calcCurrentTemplates();

    this.fireOnViewChanged();
    this.scrollSidebarDown();
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  updatePreview() {

  }

  videoCreator() {
    this.modalService.closeAll();

    this.navCtrl.navigateForward('/ai/video-creator');
  }

  watchQueue() {
    this.setPhase('queue');

    setTimeout(() => {
      this.events.publish('media:queue:watch:start', {
        itemsCount: (!!this.view.selectedItems ? this.view.selectedItems.length : 0),
      });
    }, 500);
  }

}