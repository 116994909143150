import { Injectable } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { ModalService } from 'src/app/services/core/modal.service';

import { EditPostPage } from '../../pages/post/edit-post/edit-post.page';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  pages: any;

  constructor(
    private config: ConfigService,
    private modalService: ModalService,
  ) {
  }

  create(type: string, item: object|null = null, options: any = {}) {
    return this.view(type, item, options, 'create');
  }

  edit(type: string, item: object|null = null, options: any = {}) {
    return this.view(type, item, options, 'edit');
  }

  getConfig() {
    return this.config.getConfigParam('crud') || {};
  }

  getService(serviceName: string) {
    const services: crudServiceConfig[]|null = this.getServices();
    
    if(!!services) {

      const select: any[] = services.filter((_service: any) => {
        return _service.uid === serviceName;
      });

      return (!!select && !!select[0] ? select[0] : null);
    }
  }

  getServices() {
    const config: crudConfig = this.getConfig();

    if(!!config) {
      return config.services;
    }
  }

  init() {
    this.initPages();
  }

  initPages() {
    this.pages = {
      create: {
        post: EditPostPage,
      },
      edit: {
        post: EditPostPage,
      },
    };
  }

  view(type: string, item: object|null = null, options: any = {}, mode: string = 'view') {
    return new Promise(async (resolve, reject) => {
      if(!this.pages[mode][type]) {
        reject(false);
      } else {

        const modal: any = await this.modalService.create({
          component: this.pages[mode][type],
          animated: true,
          presentingElement: await this.modalService.getTop(),
          cssClass: 'defaultModal'
        });
    
        modal.onDidDismiss().then(resolve).catch(reject);
        
        this.modalService.present(modal);
      }
    });
  }

}