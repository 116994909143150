import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from "src/app/services/core/translation.service";

@Component({
  selector: 'pipeline-selection-toolbar',
  templateUrl: './selection-toolbar.component.html',
  styleUrls: ['./selection-toolbar.component.scss']
})
export class SelectionToolbarComponent implements AfterViewInit, OnInit {

  actionSheetButtons: any;

  @Input() filters: any;
  @Input() items: any[];
  @Input() options: any;
  @Input() view: any;

  @Output() onLanguageChanged = new EventEmitter();
  @Output() onSelectionActionChanged = new EventEmitter();

  cacheKey: string = 'selection_toolbar_pref';

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private translations: TranslationService,
  ) {

  }

  calcActionSheetButtons() {
    this.actionSheetButtons = [];

    if(!!this.options && !!this.options.length) {

      let keys: string[] = this.options.map((option: any) => {
        return option.label;
      }).concat(['cancel']);

      this.translations.get(keys)
      .subscribe((translations: any) => {

        this.options.forEach((option: any) => {

          let button: any = {
            text: `${translations[option.label] || option.label}`,
            data: {
              action: option.uid,
            },
          };
  
          if(option.uid === 'delete') {
            button.role = 'destructive';
          }
  
          this.actionSheetButtons.push(button);
        });
  
        // add cancel button
        this.actionSheetButtons.push({
          text: translations.cancel || 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        });
        
      });
    }
  }

  calcViewVars() {

    if(!!this.items) {
      this.view.selectedItems = this.items.filter((item: any) => {
        return !!item.checked;
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = !!(!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.view.isLargeScreen = (window.innerWidth > 1080);

    if(!this.view.hasOwnProperty('languagesOptions')) {
     this.view.languagesOptions = {
      allowMini: true,
     };
    }
    
  }

  dateChanged(event: any = null) {
  }
  
  doRefresh() {
    this.calcViewVars();
  }

  async getPreference() {
    try {
      const fromCache: cacheItem = await this.cache.get(this.cacheKey, -1);

      if(!!fromCache && !!fromCache.data) {
        this.view.viewType = `${fromCache.data}`;
      }
    } catch(e) {
      console.warn('loading prefered view mode failed', e);
    }
  }

  ngAfterViewInit() {
    this.getPreference();
  }

  ngOnInit() {
    this.view.viewType = this.view.viewType || 'grid';

    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    this.calcActionSheetButtons();
  }

  onSelectionOptionsActionSheetDismiss(event: any) {
    if(!!event && !!event.detail) {
      console.log('selection is', event.detail);
    }
  }

  _onFilterChange(filter: any, event: any) {
    if(!!filter.hasOwnProperty('handler')) {
      let exec: any = filter.handler(filter, event);
      console.log('exec', exec);
    }
  }

  _onFilterClick(filter: any, event: any) {
    if(!!filter.hasOwnProperty('handler')) {
      let exec: any = filter.handler(filter, event);
      console.log('exec', exec);
    }
  }

  _onLanguageChanged(event: string|null = null) {
    this.view.language = event;
    this.doRefresh();

    this.onLanguageChanged.emit(this.view.language);
  }

  _onSelectionActionChanged(event: any = null) {
    this.onSelectionActionChanged.emit(`${this.view.selectionAction}`);

    setTimeout(() => {
      this.view.selectionAction = '';
    });
  }
  
  selectAll() {

    this.items.forEach((item: any) => {
      item.checked = !item.hidden && !this.view.allSelected;
    });
    
    this.view.allSelected = !this.view.allSelected;
    
    this.calcViewVars();
    
    this.events.publish('selection:changed', {
      items: this.view.selectedItems,
      last: (!!this.view.selectedItems ? this.view.selectedItems[0] : null),
    });
  }

  setView(viewType: string) {
    this.view.viewType = viewType;
    this.cache.set(this.cacheKey, `${this.view.viewType}`);
  }

  toggleFilters() {
    this.view.showFilters = !this.view.showFilters;
  }
  
}
