import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';

import { MediaPickerCardComponent } from './media-picker-card.component';

@NgModule({
  declarations: [
    MediaPickerCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    TagInputModule,
  ],
  exports: [
    MediaPickerCardComponent,
  ]
})
export class MediaPickerCardComponentModule { }