<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end" [hidden]="!view.model.type">

      <ion-button shape="round" fill="solid" color="primary" (click)="build()" *ngIf="!!view.model"
        [disabled]="!view.model.title || !view.project || !!view.loading || !view.topics || !view.topics.length"
        [hidden]="!!view.loading || (!view.startManually && (!view.model || !view.model.uid))">
        <ion-icon name="play" slot="start"></ion-icon>
        <ion-label [innerHTML]="'build'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

      <ion-button fill="clear" color="dark" (click)="build()" *ngIf="!!view.model"
        [disabled]="!view.model.title || !view.project || !!view.loading || !view.topics || !view.topics.length"
        [hidden]="!!view.loading || (!view.startManually && (!view.model || !view.model.uid))">
        <ion-icon name="play" slot="start"></ion-icon>
        <ion-label [innerHTML]="'rebuild'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

      <ion-button shape="round" fill="solid" color="primary" (click)="update()" *ngIf="!!view.model && !!view.model.uid"
        [disabled]="!view.project || !!view.loading || !view.topics || !view.topics.length"
        [hidden]="!!view.loading || (!view.startManually && (!view.model || !view.model.uid))">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <ion-popover #infoPopover [isOpen]="isInfoPopoverOpen" (didDismiss)="isInfoPopoverOpen = false">
    <ng-template>
      <ion-content>
        <p class="ion-padding" *ngIf="!!view.infoPopoverContent" [innerHTML]="view.infoPopoverContent|translate"></p>
      </ion-content>
    </ng-template>
  </ion-popover>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container main-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <ion-grid class="ai-model-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="sidebar left">

          <!-- General -->
          <div class="col-inner" [class.success]="!!view.model && !!view.model.title && !!view.model.type && !!view.model.base_model">
            
            <ion-card>

              <ion-card-header>

                <ion-card-title (click)="toggleCard('general')">
                  <span [innerHTML]="'general'|translate"></span>
                </ion-card-title>

                <ion-button class="card-toggle" (click)="toggleCard('general')" icon-only color="dark" fill="clear">
                  <ion-icon [name]="cards.general.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

              </ion-card-header>

              <ion-card-content [hidden]="!cards.general.open">
                <p [innerHTML]="'ai_model_information_text'|translate"></p>
              </ion-card-content>

              <ion-list [hidden]="!cards.general.open">

                <!-- Title -->
                <ion-item lines="none">

                  <ion-icon name="eye-outline" slot="start"></ion-icon>

                  <ion-input [label]="'title'|translate" labelPlacement="stacked" [autofocus]="true"
                    (ionChange)="onInputChanged($event)" [(ngModel)]="view.model.title"
                    [placeholder]="'ai_model_title'|translate"></ion-input>

                </ion-item>

                <!-- Type -->
                <ion-item lines="none" *ngIf="!!view.types" [disabled]="!view.model.title">

                  <ion-icon name="sparkles-outline" slot="start"></ion-icon>

                  <ion-select [disabled]="!view.model.title" [(ngModel)]="view.model.type" [label]="'type'|translate" labelPlacement="start">
                    <ion-select-option *ngFor="let type of view.types" [value]="type.uid"
                      [innerHTML]="type.label|translate"></ion-select-option>
                  </ion-select>

                </ion-item>

                <!-- Base model -->
                <ion-item lines="none" *ngIf="!!view.base_models" [hidden]="!view.model.type">

                  <ion-icon name="git-branch-outline" slot="start"></ion-icon>

                  <ion-select [(ngModel)]="view.model.base_model" [label]="'base_model'|translate"
                    labelPlacement="start">
                    <ion-select-option *ngFor="let model of view.base_models" [value]="model.uid"
                      [innerHTML]="model.label|translate"></ion-select-option>
                  </ion-select>

                </ion-item>

                <!-- Description -->
                <ion-item lines="none" [hidden]="!view.model.type">
                  <ion-icon name="information-outline" slot="start"></ion-icon>

                  <ion-textarea [rows]="view.expertMode ? 5 : 3" [label]="'description'|translate"
                    labelPlacement="stacked" (ionChange)="onInputChanged($event)" [(ngModel)]="view.model.description"
                    [placeholder]="'ai_model_description'|translate"></ion-textarea>

                </ion-item>

              </ion-list>

            </ion-card>
          </div>

          <!-- Sources -->
          <div class="col-inner" [hidden]="!view.model.type">
            <ion-card>

              <ion-card-header>

                <ion-card-title (click)="toggleCard('sources')">
                  <span [innerHTML]="'sources'|translate"></span>
                </ion-card-title>

                <ion-button class="card-toggle" (click)="toggleCard('sources')" icon-only color="dark" fill="clear">
                  <ion-icon [name]="cards.sources.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

              </ion-card-header>

              <ion-card-content [hidden]="!cards.sources.open">
                <p [innerHTML]="'ai_model_sources_text'|translate"></p>
              </ion-card-content>
            
              <ion-list>

                <ion-item *ngIf="!!view.availableIntegrations">
                  <ion-select (ionChange)="onIntegrationsChanged($event)" [(ngModel)]="view.integrations"
                    [label]="'integrations'|translate" multiple [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                    [placeholder]="'choose'|translate">
                    <ion-select-option *ngFor="let integration of view.availableIntegrations" [innerHTML]="integration.name|translate"
                      [value]="integration"></ion-select-option>
                  </ion-select>
                </ion-item>

              </ion-list>

            </ion-card>
          </div>

          <!-- Media (Object detection & image to image operations) -->
          <div class="col-inner" [hidden]="!view.model.type || (view.model.type !== 'object_detection' && view.model.type !== 'image_to_image')"
            [class.success]="!!view.mediaList && !!view.mediaList.length">
            <pipeline-media-picker-card #mediaPickerComponent [(item)]="view.media" [(items)]="view.mediaList"
              [(view)]="view"></pipeline-media-picker-card>
          </div>

          <!-- Distribution -->
          <div class="col-inner" [hidden]="!view.model.type">
            <ion-card>
              <ion-card-header>

                <ion-card-title (click)="toggleCard('distribution')">
                  <span [innerHTML]="'distribution'|translate"></span>
                </ion-card-title>

                <ion-button class="card-toggle" (click)="toggleCard('distribution')" icon-only color="dark"
                  fill="clear">
                  <ion-icon [name]="cards.distribution.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

              </ion-card-header>

              <ion-list [hidden]="!cards.distribution.open">

                <!-- Provider -->
                <ion-item>

                  <ion-avatar *ngIf="!!view.provider" slot="start">
                    <ion-img [src]="view.provider.photo || fallbackImg"
                      (ionError)="thumbnailLoadingFailed(view.provider)"></ion-img>
                  </ion-avatar>

                  <ion-select (ionChange)="onProviderChanged($event)" [(ngModel)]="view.provider"
                    [label]="'provider'|translate" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                    [placeholder]="'choose'|translate">
                    <ion-select-option *ngFor="let provider of view.providers" [innerHTML]="provider.name|translate"
                      [value]="provider"></ion-select-option>
                  </ion-select>

                  <ion-button (click)="presentInfoPopover($event, 'ai_sd_provider_infotext')" icon-only fill="clear"
                    color="dark" slot="end">
                    <ion-icon name="information-circle-outline"></ion-icon>
                  </ion-button>

                </ion-item>

                <!-- Instances -->
                <ion-item *ngIf="!!view.provider && !!view.provider.uid"
                  [disabled]="!view.instances || !view.instances.length" lines="none">
                  <ion-icon name="cube-outline" slot="start"></ion-icon>

                  <ion-select *ngIf="!!view.instances" (ionChange)="onInstancesChanged($event)"
                    [(ngModel)]="view.model.instances" multiple [label]="'instances'|translate"
                    [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [placeholder]="'choose'|translate">
                    <ion-select-option *ngFor="let instance of view.instances" [innerHTML]="instance.name|translate"
                      [value]="instance.url"></ion-select-option>
                  </ion-select>

                  <ion-button (click)="presentInfoPopover($event, 'ai_sd_instances_infotext')" icon-only fill="clear"
                    color="dark" slot="end">
                    <ion-icon name="information-circle-outline"></ion-icon>
                  </ion-button>

                </ion-item>

              </ion-list>

            </ion-card>
          </div>

        </ion-col>

        <!-- Content -->
        <ion-col #aiModelContent [size]="!!view.colSize && !!view.colSize.right ? view.colSize.right : 12">

          <pipeline-intro-card [hidden]="view.introCard.hidden || !!view.model.type" class="introCard"
            [(view)]="view.introCard"></pipeline-intro-card>

          <ion-grid [hidden]="!view.model.type">
            <ion-row>

              <ion-col>
                <ion-card>

                  <ion-card-header>
                    <ion-card-title>
                      <span [innerHTML]="'statistics'|translate"></span>
                    </ion-card-title>
                  </ion-card-header>

                  <ion-list>

                    <ion-item>
                      <ion-label [innerHTML]="'estimated_files'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                    <ion-item>
                      <ion-label [innerHTML]="'estimated_time'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                    <ion-item lines="none">

                    </ion-item>

                  </ion-list>

                </ion-card>
              </ion-col>

              <ion-col>
                <ion-card>

                  <ion-card-header>
                    <ion-card-title>
                      <span [innerHTML]="'costs'|translate"></span>
                    </ion-card-title>
                  </ion-card-header>

                  <ion-list>

                    <ion-item>
                      <ion-label [innerHTML]="'estimated_tokens'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                    <ion-item>
                      <ion-label [innerHTML]="'required_extra_token_amount'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                    <ion-item lines="none">
                      <ion-label [innerHTML]="'estimated_costs'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                  </ion-list>

                </ion-card>
              </ion-col>

              <ion-col>
                <ion-card>

                  <ion-card-header>
                    <ion-card-title>
                      <span [innerHTML]="'progress'|translate"></span>
                    </ion-card-title>
                  </ion-card-header>

                  <ion-list>

                    <ion-item>
                      <ion-label [innerHTML]="'state'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                    <ion-item>
                      <ion-label [innerHTML]="'percentage'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                    <ion-item lines="none">
                      <ion-label [innerHTML]="'action'|translate"></ion-label>
                      <ion-spinner></ion-spinner>
                    </ion-item>

                  </ion-list>

                </ion-card>
              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" [hidden]="!view.model.type">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end" [hidden]="!view.model.type">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>