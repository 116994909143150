import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";

import { Application } from '@splinetool/runtime';

@Component({
  selector: 'pipeline-spline-viewer',
  templateUrl: './spline-viewer.component.html',
  styleUrls: ['./spline-viewer.component.scss'],
})
export class SplineViewerComponent implements OnDestroy, OnInit {
  @Input() options: any;

  @ViewChild('splineCanvas', { read: ElementRef }) private canvas: ElementRef;

  app: any;
  
  view: any = {
    path: './assets/spline/dani/dani.splinecode',
  };

  constructor(
    private events: EventsService,
  ) {

  }

  applyMetaData() {
  }

  error(event: any = null) {
    console.warn('error', event);
  }

  initEvents() {
  }

  ionViewWillEnter() {
    this.applyMetaData();
  }

  initSpline() {
    setTimeout(() => {

      if(!this.canvas || !this.canvas.nativeElement) {
        return false;
      }

      this.app = new Application(this.canvas.nativeElement);

      this.loadSplineScene();
    });
  }

  async loadSplineScene() {
    
    if(!this.app) {
      return false;
    }
    
    // update spline scene if path is different
    if(!!this.view.path && (this.view.path !== this.view.last_path)) {

      await this.app.load(
        this.view.path,
        undefined,
        {
          credentials: 'include',
          mode: 'no-cors',
        }
      );

      this.view.last_path = `${this.view.path}`;
    }

    // update zoom if setting is set
    if(!!this.options && !!this.options.zoom) {
      this.app.setZoom(this.options.zoom);
    }
    
    return this.app;
  }

  ngOnChanges(changes: any) {
    if(!!changes && !!changes.options && !!changes.options.currentValue) {
      this.updateAnimation(changes.options.currentValue);
    }
  }

  ngOnDestroy() {
    if(!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();
    this.applyMetaData();

    this.initSpline();
  }

  public play() {
    return this.app.play();
  }

  public setSize(width: number, height: number) {
    try {
      return this.app.setSize(width, height);
    } catch(e) {
      return e;
    }
  }

  public setZoom(zoom: number) {
    try {
      return this.app.setZoom(zoom);
    } catch(e) {
      return e;
    }
  }

  public stop() {
    try {
      return this.app.stop();
    } catch(e) {
      return e;
    }
  }

  public async updateAnimation(options: any = null) {

    if(!!options) {
      this.options = options;
    }

    // @debug: tmp fix: flip options variables
    options = this.options;
    this.options = options || {};
    
    if(!!this.options.path || !!this.options.url) {
      this.view.path = (this.options.path || this.options.url);
      await this.loadSplineScene();
    }

    if(!!this.options.zoom) {
      await this.setZoom(this.options.zoom);
    }

    return this.app;
  }

}