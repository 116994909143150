import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from 'src/app/services/core/events.service';
import { LanguageService } from "src/app/services/core/language.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from 'src/app/services/core/user.service';

import { SpeechRecognition } from "@capacitor-community/speech-recognition";

import { SplineViewerComponent } from '../../spline/spline-viewer/spline-viewer.component';

@Component({
  selector: 'getgenius-dani',
  templateUrl: './getgenius-dani.component.html',
  styleUrls: ['./getgenius-dani.component.scss'],
})
export class GetgeniusDaniComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() config: any;
  @Input() state: any;

  @ViewChild('historyWrapper', { read: ElementRef }) private historyWrapper: ElementRef;
  @ViewChild(SplineViewerComponent) splineViewer: any;

  user: user;

  view: any = {
    animationClass: 'full',
    chat: [],
    introCard: {
      uid: 'getgenius_dani_chat_top_card',
      text: 'getgenius_dani_chat_top_card_text',
      title: 'getgenius_dani_chat_top_card_title',
    },
    loadingOptions: {},
    splineOptions: {},
  };

  webSpeechRecognition: any;

  constructor(
    private aiTools: AiToolsService,
    private dani: DaniService,
    private events: EventsService,
    private language: LanguageService,
    private navCtrl: NavController,
    private tools: ToolsService,
    private userService: UserService,
    private zone: NgZone,
  ) {
    this.user = this.userService.getUser();
  }

  public applyLoadingOptions() {
    if (!!this.config && !!this.config.zoom) {
      this.setZoom(this.config.zoom);
    }
  }

  calcViewVars() {
    this.view.isDesktop = this.tools.isDesktop();

    this.state = this.state || {};
    this.state.hasMessages = !!(!!this.view.chat && !!this.view.chat.length);
    this.state.inConversation = !!(!!this.view.chat && !!this.view.chat.length && (this.view.chat.length > 1));

    try {
      this.setSize(window.outerWidth, window.outerHeight);
    } catch(e) {
      console.warn('setting dani size failed', e);
    }

  }

  public async checkPermissions() {
    return new Promise((resolve, reject) => {
      if (this.tools.isWeb()) {
        try {
          console.log('webSpeechRecognition', this.webSpeechRecognition);
        } catch (e) {
          console.warn('speech web check failed', e);
        }
      } else {
        SpeechRecognition.checkPermissions()
          .then((response: any) => {
            resolve(!!response && !!response.speechRecognition && (response.speechRecognition === 'granted'));
          })
          .catch(reject);
      }
    })
  }

  createTicket() {

    let inputs: any[] = [
      {
        icon: 'eye-outline',
        name: 'subject',
        placeholder: 'subject',
        type: 'text',
        uid: 'subject',
      },
      {
        icon: 'text-outline',
        name: 'input',
        placeholder: 'message',
        type: 'textarea',
        uid: 'input',
      },
      {
        icon: 'person-outline',
        name: 'name',
        placeholder: 'name',
        type: 'text',
        uid: 'name',
      },
      {
        icon: 'mail-outline',
        name: 'email',
        placeholder: 'your_email',
        type: 'email',
        uid: 'email',
      },
      {
        icon: 'call-outline',
        name: 'phone',
        placeholder: 'phone',
        type: 'tel',
        uid: 'phone',
      },
    ];

    inputs.forEach((input: any, index: number) => {

      setTimeout(() => {

        this.view.chat.push({
          icon: input.icon,
          mode: 'input',
          input: `${input.name}`,
          placeholder: `${input.placeholder}`,
          role: 'system',
          type: input.type || 'text',
          uid: `${input.uid}`,
        });

        setTimeout(() => {
          this.scrollDown();
        }, 100);

      }, (index * 200));

    });

    this.view.aiPrompt = '';
  }

  initChat() {
    this.initEvents();

    if (!!this.config.hasChat && !!this.config.userCanWrite) {
      this.view.chat = this.view.chat || [];

      setTimeout(() => {
        this.view.chat.push({
          mode: 'view',
          input: `getgenius_dani_chat_intro_message`,
          role: 'assistant',
        });

        this.calcViewVars();
      }, 250);
    }
  }

  initEvents() {

    this.events.subscribe('getgenius:dani:chat:add', (message: inboxChatMessage) => {

      this.view.chat.push({
        input: message.description,
      });

      this.view.animationClass = 'profile';
    });

    this.events.subscribe('getgenius:dani:spline:set', (url: string) => {
      this.setSplineUrl(url);
    });

  }

  initSpeechRecognition() {
    try {
      if (this.tools.isWeb()) {
        try {
          const SpeechRecognition: any = (window as any).webkitSpeechRecognition;

          const onEnd = () => {
            console.log('onEnd');
            // You could do something here after listening has finished
          };

          const onResult = (result) => {
            console.log('onResult', result);
            //setValue(result);
          };

          const changeLang = (event) => {
            console.log('changeLang', event);
            //setLang(event.target.value);
          };

          const onError = (event) => {
            console.log('onError', event);

            if (event.error === "not-allowed") {
              //setBlocked(true);
            }
          };

          const { listen, listening, stop, supported } = SpeechRecognition({
            onResult,
            onEnd,
            onError,
          });

          console.log('listen', listen);
          console.log('listening', listening);
          console.log('stop', stop);
          console.log('supported', supported);

          this.view.speechRecognitionAvailable = true;

        } catch (e) {
          console.warn('speech web init failed', e);
          this.view.speechRecognitionAvailable = false;
        }
      } else {
        SpeechRecognition.available()
          .then((response: any) => {
            console.log('speech recognition response', response);
            this.view.speechRecognitionAvailable = !!response && !!response.available;
          })
          .catch((e: any) => {
            console.warn('init speech recognition failed (2)', e);
          });
      }
    } catch (e) {
      console.warn('init speech recognition failed (1)', e);
    }
  }

  public listen() {

  }

  ngAfterViewInit() {
    this.initSpeechRecognition();
  }

  ngOnChanges() {
    this.applyLoadingOptions();
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initChat();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    this.applyLoadingOptions();
  }

  public play() {
    return this.splineViewer.play();
  }

  public async record() {
    let check: any = false;

    try {
      check = await this.checkPermissions();
    } catch (e) {
      check = false;
    }

    console.log('checked permissions', check);

    if (!check) {

      try {
        SpeechRecognition.requestPermissions();
      } catch (e) {
        this.events.publish('error', e);
      }

      return false;
    }

    try {

      SpeechRecognition.start({
        language: `${this.language.getCurrentLanguage() || 'en'}-${this.language.getCurrentLanguage() || 'en'}`,
        maxResults: 2,
        prompt: "Say something",
        partialResults: true,
        popup: true,
      });

      SpeechRecognition.removeAllListeners();

      SpeechRecognition.addListener("partialResults", (data: any) => {
        console.log("partialResults was fired", data.matches);
        this.view.aiPrompt = (!!data.matches && !!data.matches[0] ? data.matches[0] : '');
      });

      this.view.listening = true;

    } catch (e) {
      this.events.publish('error', e);
      this.view.listening = false;
    }
  }

  public resend(message: any) {
    this.view.aiPrompt = `${message.input || ''}`;
    this.runAiPrompt();
  }

  public runAiPrompt(options: any = {}) {
    this.view.chat = this.view.chat || [];
    this.view.iTry = (!!options && !!options.iTry ? options.iTry : 0);
    this.view.loading = true;

    this.setSplineUrl('./assets/spline/dani/dani_working.splinecode');

    setTimeout(() => {
      this.scrollDown();

      if (this.tools.isDesktop()) {
        this.setZoom(0.5);
      } else {
        this.setZoom(0.5);
      }

    }, 100);

    let history: any[] = [];

    // add input to chat
    if (!!this.view.aiPrompt) {
      this.view.chat.push({
        input: `${this.view.aiPrompt}`,
        role: 'user',
        mode: 'view',
      });
    }

    // calculate history
    if (!!this.view.chat && !!this.view.chat.length) {
      history = this.view.chat.map((historyItem: any) => {
        return {
          input: `${historyItem.post_content || historyItem.input}`,
          role: (historyItem.role || 'user'),
          mode: historyItem.mode || 'view',
        };
      });
    }

    let aiParams: any = {
      history: history,
      post_content: `${this.view.aiPrompt}`,
    };

    this.calcViewVars();

    this.dani.sendChat(aiParams, false, this.config || {}, {
      useFunctions: true,
    })
      .then((response: any) => {
        this.view.loading = false;

        this.setZoom(1);
        this.setSplineUrl('./assets/spline/dani/dani.splinecode');

        let checkFunctionCall = this.aiTools.hasFunctionCallResponse(response);
        let blHasFunctionCall: boolean = !!(!!checkFunctionCall && !!checkFunctionCall.match);

        if (blHasFunctionCall && !!checkFunctionCall.details) {

          switch (checkFunctionCall.details.name) {
            case 'create_ticket':
              this.createTicket();
              break;
            default:
              this.aiTools.executeFunctionCall(response)
                .then((functionCallResponse: any) => {
                  console.log('functionCallResponse', functionCallResponse);
                  this.view.aiPrompt = '';
                })
                .catch((error: any) => {
                  console.warn('executing function call failed', error);
                });
              break;
          }

        } else
          if (!!response && !!response.output) {
            let split: string[] = this.tools.splitTripleBackticksWithCodeBlocks(`${response.output}`);

            this.view.chat = history;

            split.forEach((part: string, splitIndex: number) => {
              setTimeout(() => {
                if (!!part) {

                  this.view.chat.push({
                    mode: 'view',
                    input: `${this.tools.nl2br(part)}`,
                    role: 'assistant',
                  });

                  setTimeout(() => {
                    this.scrollDown();
                  }, 100);

                }
              }, (splitIndex) * 300);
            });

            this.view.aiPrompt = '';
            this.calcViewVars();
          }

        setTimeout(() => {
          this.scrollDown();
        }, 200);

      })
      .catch((error: any) => {
        this.view.loading = false;
        this.setSplineUrl('./assets/spline/dani/dani.splinecode');

        if (!!error && (error === 'Error_pipeline_ai_resource_busy') && (3 > this.view.iTry)) {
          setTimeout(() => {
            this.runAiPrompt({
              iTry: ((this.view.iTry || 0) + 1),
            });
          }, 500);
        } else {
          this.events.publish('error', error);
        }
      });
  }

  scrollDown() {
    try {
      this.historyWrapper.nativeElement.scrollTop = this.historyWrapper.nativeElement.scrollHeight;
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  public send() {

    try {
      this.stopListening();
    } catch (e) {
      console.warn('stopping to listen failed', e);
    }

    this.runAiPrompt();
  }

  public setLottieUrl(url: string) {
    this.zone.run(() => {
      this.view.splineOptions = {
        ...this.view.splineOptions,
        path: url,
      };
    });
  }

  public async setSplineUrl(url: string) {
    try {

      this.view.splineOptions = {
        ...this.view.splineOptions,
        path: url,
      };

      if(!this.splineViewer) {
        return false;
      }

      await this.splineViewer.updateAnimation(this.view.splineOptions);

    } catch (e) {
      console.warn('setting url failed', e);
    }
  }

  public setSize(width: number, height: number) {
    return this.splineViewer.setSize(width, height);
  }

  public setZoom(zoom: number) {

    this.view.splineOptions = {
      ...this.view.splineOptions,
      zoom: zoom,
    };

    if(!this.splineViewer) {
      return false;
    }

    return this.splineViewer.setZoom(zoom);
  }

  public stop() {

    if(!this.splineViewer) {
      return false;
    }
    
    return this.splineViewer.stop();
  }

  public stopListening() {
    this.view.listening = false;

    try {
      SpeechRecognition.stop();
    } catch (e) {
      console.warn('stopping failed', e);
    }
  }

  toChatPage() {
    this.navCtrl.navigateForward('/dani/chat');
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}